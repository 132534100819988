import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    TextField,
    CircularProgress,
} from '@mui/material';
import { Home, FilterAlt } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { NotificationIcon } from '../../../common/Icons';
import { listaAlertas } from './configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { checkPermissionsAndRedirect, renderDay, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { AlertRow } from './AlertRow';

export function ListOfAlerts() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [alertas, setAlertas] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    const carregaAlertas = async (id_empresas, data_inicio, data_fim) => {
        try {
            setIsLoading(true);

            data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
            data_fim = dayjs(data_fim).format('YYYY-MM-DD');

            const filterPaginated = {
                id_empresas: Array.isArray(id_empresas) ? id_empresas : [id_empresas],
                data_inicio,
                data_fim,
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };

            const res = await listaAlertas(filterPaginated);
            setAlertas(res.data.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            console.log(error);
            exibirAlerta('Erro', 'Erro ao carregar alertas', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const updateAlertStatus = (alertId, status) => {
        setAlertas(prevAlerts =>
            prevAlerts.map(alerta => {
                if (alerta.id === alertId) {
                    alerta.status = status;
                }
                return alerta;
            })
        );
    }

    const onSubmit = async (data) => {
        try {
            carregaAlertas(data.id_empresas, data.data_inicio, data.data_fim);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao buscar dados', 'error');
        }
    };

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_alerta", "list_alerta"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        let defaultEmpresaId = null;
        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            defaultEmpresaId = selectedEmpresaIdSession;
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);

                if (!selectedEmpresaIdSession) {
                    defaultEmpresaId = empresasData[0].id;
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }

                const defaultValues = {
                    id_empresas: defaultEmpresaId,
                    data_inicio: dayjs().subtract(1, 'month'),
                    data_fim: dayjs(),
                };

                reset(defaultValues);

                carregaAlertas(defaultValues.id_empresas, defaultValues.data_inicio, defaultValues.data_fim);
            }));

        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        const id_empresas = selectedEmpresaId;
        const data_inicio = getValues('data_inicio');
        const data_fim = getValues('data_fim');

        if (!id_empresas || !data_inicio || !data_fim) {
            return;
        }

        carregaAlertas(id_empresas, data_inicio, data_fim);
    }, [page, rowsPerPage]);

    return (<>
        <Helmet title='Alertas' defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <LinkNavegacao to='/epis/alertas'>Alertas</LinkNavegacao>
                    <Typography variant='span'>Alertas Disparados</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<NotificationIcon fontSize='large' />}
                    title='Alertas Disparados'
                    description='Visualize os Alertas que foram disparados.'
                />
            </Grid>

            <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} md={4}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        id_empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setValue('id_empresas', value, { shouldDirty: true });
                            setPage(0);
                        }}
                        textHelper={false}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <DatePicker
                        sx={{ width: '100%' }}
                        label="Data de Início"
                        size='small'
                        format="DD/MM/YYYY"
                        openTo="day"
                        maxDate={dayjs()}
                        dayOfWeekFormatter={renderDay}
                        defaultValue={dayjs().subtract(1, 'month')}
                        onChange={(data) => {
                            setValue('data_inicio', data, { shouldDirty: true })
                            if (data && data.isBefore(dayjs())) {
                                clearErrors('data_inicio');
                            }
                        }}
                        onError={(error) => {
                            if (error) {
                                const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                                setError("data_inicio", { type: "invalid", message: errorMessage })
                            }
                        }}
                        slotProps={{
                            textField: {
                                error: !!errors.data_inicio,
                                helperText: errors.data_inicio ? errors.data_inicio.message : null,
                                size: 'small',
                                style: {
                                    backgroundColor: '#fff',
                                    borderRadius: 4,
                                }
                            }
                        }}
                        TextField={(params) => (
                            <TextField
                                size='small'
                                autoComplete='off'
                                fullWidth
                                type="date"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <DatePicker
                        sx={{ width: '100%' }}
                        label="Data de Fim"
                        size='small'
                        format="DD/MM/YYYY"
                        openTo="day"
                        maxDate={dayjs().add(1, 'day')}
                        dayOfWeekFormatter={renderDay}
                        defaultValue={dayjs()}
                        onChange={(data) => {
                            setValue('data_fim', data, { shouldDirty: true })
                            if (data && data.isBefore(dayjs().add(1, 'day'))) {
                                clearErrors('data_fim');
                            }
                        }}
                        onError={(error) => {
                            if (error) {
                                const errorMessage = error === "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                                setError("data_fim", { type: "invalid", message: errorMessage })
                            }
                        }}
                        slotProps={{
                            textField: {
                                error: !!errors.data_fim,
                                helperText: errors.data_fim ? errors.data_fim.message : null,
                                size: 'small',
                                style: {
                                    backgroundColor: '#fff',
                                    borderRadius: 4,
                                }
                            },
                        }}
                        TextField={(params) => (
                            <TextField
                                size='small'
                                autoComplete='off'
                                fullWidth
                                type="date"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={1}>
                    <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        disabled={
                            isLoading ||
                            Object.keys(errors).length > 0 ||
                            !verifyFieldsAreFilled([getValues('id_empresas'), getValues('data_inicio'), getValues('data_fim')])
                        }
                        startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
                    >
                        Filtrar
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Alertas">
                        <TableHead>
                            <TableRow>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Evento</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Envio</TableCell>
                                <TableCell>Lido/Resolvido</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : alertas && alertas?.length > 0
                                    ? alertas.map((item) =>
                                        <AlertRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleUpdateStatus={updateAlertStatus}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    </>);
}