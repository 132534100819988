import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format, parse } from 'date-fns';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from '@mui/material/colors';
import { categoryIcons } from './constants';

function LinkMenu({ children, url, setDrawerOpen }) {
  return (<Link onClick={() => { setDrawerOpen(false) }} to={url} style={{ textDecoration: 'none', color: 'inherit' }}>{children}</Link>)
}

function formatarCNPJ(cnpj) {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // Adiciona zeros à esquerda se necessário
  while (cnpj.length < 14) {
    cnpj = '0' + cnpj;
  }

  // Formata o CNPJ no padrão com pontos, barra e traço
  return `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12)}`;
}

function formatarCPF(cpf) {
  // Remove non-numeric characters
  cpf = cpf.replace(/\D/g, '');

  // Add leading zeros if necessary
  while (cpf.length < 11) {
    cpf = '0' + cpf;
  }

  // Format the CPF in the standard format with dots and dash
  return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9)}`;
}

function validateCPF(value) {
  // Remove caracteres não numéricos
  const cpf = value.replace(/\D/g, '');

  // Verifica se a string tem 11 caracteres
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os caracteres são iguais, o que torna o CPF inválido
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  const multiplicadoresDigito1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
  const multiplicadoresDigito2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
  const valores = cpf.slice(0, 9).split('');
  let soma = 0;

  // Verificador do primeiro dígito
  for (let i = 0; i < multiplicadoresDigito1.length; i++) {
    soma += (parseInt(valores[i]) * multiplicadoresDigito1[i]);
  }
  let digito1 = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

  // Verificador do segundo dígito
  soma = 0;
  valores.push(digito1);
  for (let i = 0; i < multiplicadoresDigito2.length; i++) {
    soma += (parseInt(valores[i]) * multiplicadoresDigito2[i]);
  }
  let digito2 = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

  if ((`${digito1}${digito2}`) !== cpf.slice(-2)) {
    return false;
  }

  return true;
}

function validatePhoneNumber(phone) {
  if (!phone) {
    return true;
  }

  const phoneRegex = /^(\+55)?\s?\(?\d{2}\)?[\s-]?\d{4,5}[\s-]?\d{4}$/;
  return phoneRegex.test(phone);
}

function validateCNPJ(value) {
  // Remove caracteres não numéricos
  const cnpj = value.replace(/\D/g, '');
  // Verifica se a string tem 14 caracteres
  if (cnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os caracteres são iguais, o que torna o CNPJ inválido
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }
  const multiplicadores = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const valores = cnpj.slice(0, 13).split('');
  let soma = 0;

  //verificador 01
  for (let i = 0; i < multiplicadores.length - 1; i++) {
    soma += (parseInt(valores[i]) * multiplicadores[i + 1])
  }
  const verificador01 = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

  //verificador 02
  soma = 0;
  for (let i = 0; i < multiplicadores.length; i++) {
    soma += (parseInt(valores[i]) * multiplicadores[i])
  }

  let verificador02 = (soma % 11) < 2 ? 0 : 11 - (soma % 11);
  if ((`${verificador01}${verificador02}`) !== cnpj.slice(-2)) return false


  return true;

}

// Função maskCNPJ
function maskCNPJ(value) {
  let cnpj = value ? value.replace(/\D/g, '') : '';

  if (cnpj.length > 14) {
    cnpj = cnpj.slice(0, 14)
  }

  if (!cnpj) {
    return '';
  }

  let maskedCNPJ = '';
  const tamanho = cnpj.length;

  if (tamanho <= 2) {
    maskedCNPJ = `__.___.___/____-${cnpj.padStart(2, "_")}`
  } else {
    if (tamanho <= 6) {
      const parte02 = cnpj.slice(0, -2)
      maskedCNPJ = `__.___.___/${parte02.padStart(4, "_")}-${cnpj.slice(-2)}`
    } else {
      if (tamanho <= 9) {
        const parte03 = cnpj.slice(0, -6)
        maskedCNPJ = `__.___.${parte03.padStart(3, "_")}/${cnpj.slice(-6, -2)}-${cnpj.slice(-2)}`
      } else {
        if (tamanho <= 12) {
          const parte04 = cnpj.slice(0, -9)
          maskedCNPJ = `__.${parte04.padStart(3, "_")}.${cnpj.slice(-9, -6)}/${cnpj.slice(-6, -2)}-${cnpj.slice(-2)}`
        } else {
          const parte05 = cnpj.slice(0, -12)
          maskedCNPJ = `${parte05.padStart(2, "_")}.${cnpj.slice(-12, -9)}.${cnpj.slice(-9, -6)}/${cnpj.slice(-6, -2)}-${cnpj.slice(-2)}`

        }
      }
    }
  }

  return maskedCNPJ; // Preenche com underline caso não tenha 14 dígitos ainda
};

function maskCPF(v) {
  if (!v) {
    return '';
  };

  // transforma o valor em string utilizando o método toString
  v = v.toString();

  if (v.length > 14) {
    v = v.slice(0, 14);
    return v;
  }
  v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
  return v
}

// verify if the value contains only numbers
const onlyContainsNumbers = (value) => /^\d+$/.test(value);

function maskPhoneNumber(phone) {
  if (!phone) {
    return '';
  }

  let size = 0;
  if (onlyContainsNumbers(phone)) {
    size = 11;
  } else {
    size = 15;
  }

  if (phone.length >= size) {
    return phone.replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d{1,6})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  } else {
    return phone.replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{4})(\d{1,4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
}

// Função para remover a máscara do CNPJ
function unmaskValue(value) {
  if (!value) {
    return "";
  }
  value = String(value);
  return value.replace(/\D/g, '');
};

function maskCEP(value) {
  let cep = value ? value.replace(/[^\d]/g, '') : false;

  if (cep.length > 8) {
    cep = cep.slice(0, 8)
  }

  if (!cep) {
    return '';
  }

  let maskedCEP = '';
  const tamanho = cep.length;

  if (tamanho <= 3) {
    maskedCEP = `_____-${cep.padStart(3, "_")}`
  } else {
    const parte01 = cep.slice(0, -3);
    const parte02 = cep.slice(-3);
    maskedCEP = `${parte01.padStart(5, "_")}-${parte02}`
  }

  return maskedCEP; // Preenche com underline caso não tenha 8 dígitos ainda
};

function validateCEP(value) {
  return ((value.replace(/\D/g, '')).length === 8) ? true : false;
}

function hasPermission(requiredPermissions) {
  const data = localStorage.getItem("@STW:userData");
  const userData = JSON.parse(data)

  const userPermissions = userData.habilidades;

  return requiredPermissions.some(permission => userPermissions.includes(permission));
}

function checkPermissionsAndRedirect(requiredPermissionsView) {
  if (!hasPermission(requiredPermissionsView)) {
    redirectUnauthorizedUser();
  }
}

function redirectUnauthorizedUser(message = "Você não tem permissão para acessar essa página") {
  displayAlertMessageStorage(message);
  window.location = '/'
}

function displayAlertMessageStorage(message = "Você não tem permissão para acessar essa página") {
  const alertObj = {
    title: 'Ops',
    message,
    type: 'error'
  };
  localStorage.setItem("@STW:alert", JSON.stringify(alertObj));
}

async function getAddressByCEP(cep) {
  return axios.get(`${process.env.REACT_APP_CONSULTA_CEP_URL}/ws/${cep}/json/`);
}

function trataCompaniesCheckBoxArray(itens) {
  if (itens) {
    let companies = []
    // eslint-disable-next-line
    for (const [_, valor] of Object.entries(itens)) {
      if (valor)
        companies.push(valor)
    }
    return companies

  } else {

    return []

  }

}

function converterData(formatoEntrada, formatoSaida, data) {
  // Parse da data de entrada para objeto Date
  const dataParseada = parse(data, formatoEntrada, new Date());

  // Formatação da data para o formato de saída desejado
  const dataFormatada = format(dataParseada, formatoSaida);

  return dataFormatada;
}

function formatDateTimeToDate(date) {
  return dayjs(date).format('DD-MM-YYYY');
}

function convertToIsoDate(value) {
  return dayjs(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
}

const renderDay = (day, _date) => {

  switch (day) {
    case 'Su': return <Typography sx={{ color: 'red' }}>D</Typography>;
    case 'Mo': return <Typography>S</Typography>;
    case 'Tu': return <Typography>T</Typography>;
    case 'We': return <Typography>Q</Typography>;
    case 'Th': return <Typography>Q</Typography>;
    case 'Fr': return <Typography>S</Typography>;
    default: return <Typography>S</Typography>;

  }
};

function getFormErrorMessage(errors, inputName) {
  const error = errors[inputName];

  if (!error) return;

  if (inputName === 'cpf' || inputName === 'cnpj' || inputName === 'cep' || inputName === 'rg') {
    inputName = inputName.toUpperCase();
  } else if (inputName === 'dt_nascimento') {
    inputName = 'Data de Nascimento';
  } else if (inputName === 'fantasy_name') {
    inputName = 'Nome Fantasia';
  } else if (inputName === 'company_name') {
    inputName = 'Razão Social';
  }

  if (error.type == 'required') {
    if (inputName === 'new_password' || inputName === 'new_password_confirmation') {
      return 'O campo é obrigatório';
    }

    if (inputName.includes('_')) {
      const inputNameString = inputName.split('_').join(' ');
      return `O campo ${inputNameString} é obrigatório`;
    }

    return `O campo ${inputName} é obrigatório`;
  }
  else if (error.type === 'invalid') {
    return 'O campo ' + inputName + ' é inválido';
  }
  else if (error.type === 'minLength') {
    const inputFirstLetterUppercase = inputName[0].toUpperCase() + inputName.slice(1);
    return inputFirstLetterUppercase + ' deve possuir no mínimo 8 caracteres';
  }

  return error.message;
}

function fillInputErrors(error, setErrorForm) {
  if (!error.response.data.errors) return;
  Object.entries(error.response.data.errors).forEach(([label, value]) => {
    setErrorForm(label, { type: "manual", message: value[0] })
  });
}

function validatePasswordRegex(password) {
  // valida se a senha ao menos uma letra e um número
  const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  return regex.test(password);
}

const translateItemAriaLabel = (type, page, selected) => {
  if (type === 'first') {
    return 'Primeira página';
  }
  if (type === 'last') {
    return 'Última página';
  }
  if (type === 'next') {
    return 'Próxima página';
  }
  if (type === 'previous') {
    return 'Página anterior';
  }
  return `Página ${page + 1} ${selected ? 'selecionada' : ''}`;
}

const translateLabelDisplayedRows = ({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`;

const generateColors = () => {
  const colors = [
    green[600], blue[600], yellow[600], red[600],
    indigo[500], lime[600], purple[600], teal[600],
    orange[600], brown[600], cyan[600], pink[600],
    amber[600], deepOrange[600], lightGreen[600], grey[800],
    deepPurple[600], lightBlue[600], blueGrey[600]
  ];

  return colors;
}

const getCategoryIconByName = (name) => {
  const DefaultIcon = categoryIcons[0].component;
  const icon = categoryIcons.find(icon => icon.name == name);
  return icon ? <icon.component fontSize="large" /> : <DefaultIcon fontSize="large" />;
}

function verifyFieldsAreFilled(fields) {
  return fields.every(field => field);
}

export {
  LinkMenu,
  formatarCNPJ,
  formatarCPF,
  validateCPF,
  maskCEP,
  maskCPF,
  maskPhoneNumber,
  validatePhoneNumber,
  maskCNPJ,
  validateCNPJ,
  unmaskValue,
  validateCEP,
  hasPermission,
  checkPermissionsAndRedirect,
  redirectUnauthorizedUser,
  displayAlertMessageStorage,
  getAddressByCEP,
  trataCompaniesCheckBoxArray,
  converterData,
  renderDay,
  formatDateTimeToDate,
  convertToIsoDate,
  getFormErrorMessage,
  fillInputErrors,
  validatePasswordRegex,
  translateItemAriaLabel,
  translateLabelDisplayedRows,
  generateColors,
  getCategoryIconByName,
  verifyFieldsAreFilled
}