import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Tooltip,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { listaSetores, manipulaDepartamento } from './configs/functions';
import { listaUsuarios, listaUsuariosFilter } from '../people/configs/functions';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';

export function DepartmentForm({ open, setOpen, selectedDepartment, empresas }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);
    const [coordenadores, setCoordenadores] = useState([]);
    const [setores, setSetores] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [isLoadingCompanyData, setIsLoadingCompanyData] = useState(false);

    const isEditAction = !!selectedDepartment;

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const listaColaboradores = async (empresaId) => {
        try {
            const response = await listaUsuariosFilter({ id_empresa: empresaId });
            setCoordenadores(response.data.data);
        } catch (error) {
            exibirAlerta('Erro ao carregar os Colaboradores', '', 'error');
        }
    }

    const carregaSetores = async (empresaId) => {
        try {
            const filterAll = { id_empresas: Array.isArray(empresaId) ? empresaId : [empresaId] };
            const response = await listaSetores(filterAll);
            setSetores(response.data.data);
        } catch (error) {
            exibirAlerta('Erro ao carregar os Setores', '', 'error');
        }
    }

    const onSubmit = async (data) => {
        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedDepartment.id;

            if (dirtyData?.id_empresas) {
                formDataToSend.id_empresas = Array.isArray(dirtyData.id_empresas) ? dirtyData.id_empresas : [dirtyData.id_empresas];
            }

            // se for edição, o id_setor é um array
            if (dirtyData?.id_setor) {
                formDataToSend.id_setores = Array.isArray(dirtyData.id_setor) ? dirtyData.id_setor : [dirtyData.id_setor];
            }
        } else {
            delete data.id;

            if (data.id_setor === "") {
                delete data.id_setor;
            }

            formDataToSend = data;
            formDataToSend.id_empresas = Array.isArray(data.id_empresas) ? data.id_empresas : [data.id_empresas];
        }

        try {
            const res = await manipulaDepartamento(formDataToSend, isEditAction);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao cadastrar Departamento.";
            if (error.response) {
                errorMessage = error.response.data.message
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            const defaultValues = {
                id: selectedDepartment ? selectedDepartment.id : '',
                titulo: selectedDepartment ? selectedDepartment.titulo : '',
                status: selectedDepartment ? selectedDepartment.status : 1,
                descricao: selectedDepartment ? selectedDepartment.descricao : '',
                cod_externo: selectedDepartment ? selectedDepartment.codExterno : '',
                coordenador_id: selectedDepartment ? selectedDepartment.coordenador_id : '',
                id_setor: selectedDepartment ? selectedDepartment.setor[0]?.id : '',
                id_empresas: selectedDepartment ? selectedDepartment.empresa.id : '',
            };
            if (selectedDepartment) {
                setSelectedEmpresaId(selectedDepartment.empresa.id);
            }
            reset(defaultValues);
        } else {
            reset();
            setSelectedEmpresaId(null);
        }
    }, [open, reset]);

    async function getDataEmpresa(empresaId) {
        setIsLoadingCompanyData(true);
        await listaColaboradores(empresaId);
        await carregaSetores(empresaId);
        setIsLoadingCompanyData(false);
    }

    useEffect(() => {
        if (selectedEmpresaId) {
            getDataEmpresa(selectedEmpresaId);
        }
    }, [selectedEmpresaId]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Departamento <strong>{selectedDepartment.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar novo Departamento</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Departamento.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Título'
                                        placeholder='Digite o título do Departamento'
                                        name='titulo'
                                        fullWidth
                                        {...register("titulo", { required: true })}
                                        error={!!errors.titulo}
                                        helperText={getFormErrorMessage(errors, 'titulo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Código Externo'
                                        placeholder='Digite o código externo'
                                        name='cod_externo'
                                        fullWidth
                                        {...register("cod_externo", { required: false })}
                                        error={!!errors.cod_externo}
                                        helperText={getFormErrorMessage(errors, 'cod_externo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                {empresas &&
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name='id_empresas'
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={selectedDepartment ? selectedDepartment.empresa.id : ""}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    fullWidth
                                                    sx={{ mt: 1 }}
                                                    label="Empresa"
                                                    onChange={(e) => {
                                                        setSelectedEmpresaId(e.target.value);
                                                        setValue('id_empresas', e.target.value, { shouldDirty: true })
                                                        setValue('coordenador_id', '', { shouldDirty: true })
                                                        if (e.target.value) clearErrors("id_empresas");
                                                    }}
                                                    error={!!errors.id_empresas}
                                                    helperText={getFormErrorMessage(errors, 'id_empresas')}
                                                >
                                                    {empresas.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.nomeFantasia}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                }

                                {coordenadores &&
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled isDisabled={selectedEmpresaId || (selectedDepartment && selectedDepartment.coordenador.id)}>
                                            <Controller
                                                name='coordenador_id'
                                                control={control}
                                                // rules={{ required: true }}
                                                defaultValue={selectedDepartment ? selectedDepartment.coordenador.id : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Coordenador"
                                                        disabled={!selectedEmpresaId}
                                                        onChange={(e) => {
                                                            setValue('coordenador_id', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) {
                                                                clearErrors("coordenador_id");
                                                            }
                                                        }}
                                                        error={!!errors.coordenador_id}
                                                        helperText={getFormErrorMessage(errors, 'coordenador_id')}
                                                    >
                                                        {!isLoadingCompanyData ?
                                                            (
                                                                coordenadores.length > 0 ? (
                                                                    coordenadores.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.matricula} - {option.nome}
                                                                        </MenuItem>
                                                                    ))
                                                                ) : <MenuItem disabled>Nenhum disponível</MenuItem>
                                                            )
                                                            : <MenuItem disabled>Carregando...</MenuItem>
                                                        }
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                }

                                {setores &&
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled isDisabled={selectedEmpresaId || (selectedDepartment && selectedDepartment.setor[0]?.id)}>
                                            <Controller
                                                name='id_setor'
                                                control={control}
                                                defaultValue={selectedDepartment ? selectedDepartment.setor[0]?.id : ""}
                                                render={({ field: { ref, onChange, value, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        value={value || ''}
                                                        sx={{ mt: 1 }}
                                                        label="Setor"
                                                        disabled={!selectedEmpresaId}
                                                        onChange={(e) => {
                                                            setValue('id_setor', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) {
                                                                clearErrors("id_setor");
                                                            }
                                                        }}
                                                        error={!!errors.id_setor}
                                                        helperText={getFormErrorMessage(errors, 'id_setor')}
                                                    >
                                                        {!isLoadingCompanyData ?
                                                            (setores.length > 0 ? (
                                                                setores.map((option) => (
                                                                    <MenuItem key={option.setores_id[0]} value={option.setores_id[0]}>
                                                                        {option.titulo}
                                                                    </MenuItem>
                                                                ))
                                                            ) : <MenuItem disabled>Nenhum disponível</MenuItem>
                                                            )
                                                            :
                                                            <MenuItem disabled>Carregando...</MenuItem>
                                                        }
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}