import { MenuItem } from '@mui/material';
import { GridToolbarContainer, GridToolbarExportContainer, useGridApiContext } from '@mui/x-data-grid';
import { CsvIcon, DocumentIcon, PdfIcon, Xls502Icon } from './Icons';
import { exportToExcel } from '../screens/epis/reports/configs/utils';

export const CustomToolbarReports = ({ reportName, columns, pdfExport, customItem, ...rest }) => {
  const apiRef = useGridApiContext();

  const handleExportCsv = (options) => apiRef.current.exportDataAsCsv(options);
  const handleExportExcel = () => exportToExcel(reportName, columns, apiRef);

  return (
    <GridToolbarContainer {...rest} sx={{ justifyContent: 'flex-end' }}>
      <GridToolbarExportContainer {...rest}>
        {/* export xls */}
        <MenuItem onClick={handleExportExcel}>
          <Xls502Icon sx={{ mr: 1 }} /> Exportar como Excel
        </MenuItem>

        {/* export csv */}
        <MenuItem onClick={handleExportCsv}>
          <CsvIcon sx={{ mr: 1 }} /> Exportar como CSV
        </MenuItem>

        {/* export pdf */}
        <MenuItem onClick={pdfExport}>
          <PdfIcon sx={{ mr: 1 }} /> Exportar como PDF
        </MenuItem>

        {customItem}
      </GridToolbarExportContainer>
    </GridToolbarContainer >
  );
};