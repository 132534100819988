import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
    Avatar,
} from '@mui/material';
import { Home, Add } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { atualizaProduto, deletaProduto, listaProdutos } from './configs/functions';
import { EditIcon, ProtectiveGearAIcon, TrashIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, getCategoryIconByName, hasPermission } from '../../../../configs/functions';
import { ProductForm } from './ProductForm/index';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { listaEmpresas } from '../../management/companies/configs/functions';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useSearchParams } from 'react-router-dom';
import { FiltersButton } from '../../../common/FiltersButton';
import { Pagination } from '../../../common/Pagination';
import { ProductsTableFilter } from './components/ProductsTableFilter';

const ProductRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status ? true : false);
    const [openDialog, setOpenDialog] = useState(false);
    const [imgError, setImgError] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_produtos", "update_produtos"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaProduto(data.id, { status: !active });
            if (res) {
                const message = active ? 'Produto inativado com sucesso' : 'Produto ativado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const handleDeleteProduto = () => {
        const id = data.id;
        deletaProduto(id)
            .then((response) => {
                const message = response.data.message ?? 'Produto inativado com sucesso';
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow key={data.id}>
                <TableCell>{data.ca ? data.ca : 'N/A'}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={4} alignItems="center" gap={2}>
                        <Avatar
                            sx={{
                                bgcolor: grey[100], color: grey[600], maxWidth: 38, maxHeight: 38
                            }}
                            onDragStart={(e) => e.preventDefault()}
                        >
                            {data.img && !imgError ? (
                                <img
                                    src={data.img}
                                    alt={data.nome}
                                    style={{ width: '100%' }}
                                    onError={() => setImgError(true)}
                                />
                            ) : getCategoryIconByName(data.categoria.icon_name)}
                        </Avatar>

                        {data.nome}
                    </Stack>
                </TableCell>
                <TableCell>{(data.categoria.nome)}</TableCell>
                <TableCell><Typography noWrap sx={{ display: 'block', width: '25rem' }}>{data.observacao}</Typography></TableCell>
                <TableCell align='right'>
                    <Switch checked={active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_produtos", "update_produtos"])} />
                </TableCell>
                <TableCell sx={{ minWidth: 110 }}>
                    {hasPermission(["admin", "admin_produtos", "update_produtos"]) && (
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    {hasPermission(["admin", "admin_produtos", "delete_produtos"]) && (
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir este produto?'}
                title={'Excluir Produto'}
                goAction={handleDeleteProduto}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

function ListOfProducts() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    const [searchParams, setSearchParams] = useSearchParams();

    const [produtos, setProdutos] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    // estados para controle de paginacao
    const page = parseInt(searchParams.get('page') || 1) - 1;
    const rowsPerPage = parseInt(searchParams.get('perPage') || 10);

    const [isLoadingList, setIsLoadingList] = useState(true);
    const [numTotalItems, setNumTotalItems] = useState(0);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);
    const [empresas, setEmpresas] = useState([]);

    const loadEmpresas = useCallback(async () => {
        try {
            const response = await listaEmpresas();
            const empresasData = response?.data.data || [];
            setEmpresas(empresasData);

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);

            setSearchParams((state) => {
                state.set('page', String(page + 1));
                state.set('perPage', String(rowsPerPage));
                state.set('id_empresa', String(selectedEmpresaId));

                return state;
            });
        } catch (error) {
            console.log('Failed to load empresas:', error);
            exibirAlerta('Erro', 'Erro ao carregar empresas.', 'error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const carregaProdutos = useCallback(async (queryFilters) => {
        if (!queryFilters.id_empresa) {
            return;
        }

        try {
            setIsLoadingList(true);
            const paginationFilter = {
                limit: rowsPerPage,
                offset: page * rowsPerPage,
                ...queryFilters,
            };

            setEmpresaIdSession(queryFilters.id_empresa);

            const res = await listaProdutos(paginationFilter);
            setNumTotalItems(res?.data.numero_total);
            setProdutos(res?.data.data);
        } catch (error) {
            console.log('Erro ao carregar Produtos', error);
            exibirAlerta('Ops', 'Erro ao carregar Produtos, tente novamente mais tarde.', 'error');
        } finally {
            setIsLoadingList(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const updateStatus = (productId, status) => {
        setProdutos((produtos) =>
            produtos.map((item) => item.id === productId ? { ...item, status } : item)
        );
    }

    function handleEditFunction(item) {
        setSelectedProduct(item);
        setOpenForm(true);
    }

    useEffect(() => {
        if (openForm) return;
        setSelectedProduct(null);

        const queryFilters = { ...Object.fromEntries(searchParams) }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        carregaProdutos(tableFilters);

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);
        setActiveFilters(activeFilters);
    }, [openForm, searchParams, carregaProdutos]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_produtos", "list_produtos"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        loadEmpresas();
    }, [loadEmpresas]);

    return (<>
        <Helmet title='Produtos' defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="end"
            spacing={3}
        >
            <Grid item xs={12} md={9}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao >
                    <LinkNavegacao to='/epis/produtos'>Produtos</LinkNavegacao >
                    <Typography variant='span'>Produtos Cadastrados</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<ProtectiveGearAIcon fontSize='large' />}
                    title='Produtos Cadastrados'
                    description='Administre os produtos disponíveis na(s) sua(s) empresa(s).'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <FiltersButton
                        isOpenFilters={isOpenFilters}
                        setIsOpenFilters={setIsOpenFilters}
                        activeFilters={activeFilters}
                    />

                    {hasPermission(["admin", "admin_produtos", "create_produtos"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar novo produto
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <ProductsTableFilter
                isOpenFilters={isOpenFilters}
                isLoadingList={isLoadingList}
                empresas={empresas}
            />

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Produtos">
                        <TableHead>
                            <TableRow>
                                <TableCell>CA</TableCell>
                                <TableCell>Produto</TableCell>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell align='right'>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoadingList
                                ? <LoadingTableRow />
                                : produtos && produtos.length > 0 ? (
                                    produtos?.map((item) => (
                                        <ProductRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditFunction}
                                            handleUpdateStatus={updateStatus}
                                            updateData={() => {
                                                carregaProdutos({ ...Object.fromEntries(searchParams) })
                                            }}
                                        />
                                    ))
                                ) : <EmptyTableRow infoType='produto' />
                            }
                        </TableBody>
                    </Table>

                    <Pagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <ProductForm
            open={openForm}
            setOpen={setOpenForm}
            selectedProduct={selectedProduct}
        />
    </>);
}

export default ListOfProducts;