import { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, Tooltip } from '@mui/material';
import { Controller } from "react-hook-form";
import { carregaPontosEntrega } from '../../configs/functions';
import { getFormErrorMessage } from '../../../../../../configs/functions';
import { useCommonItems } from '../../../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../../../common/TooltipSelectDisabled';

export function FirstStepEquipmentForm({ values, equipamento, empresas, register, errors, clearErrors, control, setQtdMotores, setValue }) {
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const [pontosEntrega, setPontosEntrega] = useState([]);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

  const equipmentContractTypes = [{ value: 'locacao', text: 'Locação' }, { value: 'venda', text: 'Venda' }];
  const quantidadeMotoresValues = [{ value: 36, text: '36 Motores' }, { value: 60, text: '60 Motores' }];

  const loadPontosEntrega = async (empresaId) => {
    try {
      const filterOnlyActives = {
        // status: 1,
        id_empresa: empresaId
      };
      const response = await carregaPontosEntrega(filterOnlyActives);
      setPontosEntrega(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Pontos de Entrega', '', 'error');
    }
  }

  useEffect(() => {
    if (values.id_empresa) {
      setSelectedEmpresaId(values.id_empresa);
    }

    if (equipamento) {
      setSelectedEmpresaId(equipamento.empresa.id);
    }

    return () => setSelectedEmpresaId(null);
  }, [equipamento]);

  async function getValuesFromEmpresaId(empresaId) {
    exibirDialog('Carregando dados da Empresa...');
    try {
      await loadPontosEntrega(empresaId);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Pontos de Entrega', '', 'error');
    } finally {
      handleCloseDialog();
    }
  }

  useEffect(() => {
    if (selectedEmpresaId) {
      getValuesFromEmpresaId(selectedEmpresaId);
    }
  }, [selectedEmpresaId]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <TextField
          autoFocus
          InputLabelProps={{ shrink: true }}
          margin='dense'
          label='Nome'
          placeholder='Digite o nome do Equipamento'
          name='nome'
          fullWidth
          {...register("nome", { required: true })}
          error={!!errors.nome}
          helperText={getFormErrorMessage(errors, 'nome')}
          autoComplete='off'
          inputProps={{ form: { autocomplete: 'off' } }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          InputLabelProps={{ shrink: true }}
          margin='dense'
          label='Código de Identificação'
          placeholder='Digite o código de identificação'
          name='tag_identificacao'
          fullWidth
          {...register("tag_identificacao")}
          error={!!errors.tag_identificacao}
          helperText={!!errors.tag_identificacao ? getFormErrorMessage(errors, 'tag_identificacao') : 'O código será criado automaticamente se não preenchido.'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          select
          label="Quantidade de motores"
          name='qtd_motores'
          fullWidth
          defaultValue={equipamento ? equipamento.qtd_motores : values.qtd_motores || 36}
          error={!!errors.qtd_motores}
          helperText={getFormErrorMessage(errors, 'qtd_motores')}
          {...register("qtd_motores", { required: true })}
          sx={{ mt: 1 }}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setQtdMotores(value)
            setValue('qtd_motores', value, { shouldDirty: true })
          }}
        >
          {quantidadeMotoresValues.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: true }}
          margin='dense'
          label='Descrição'
          placeholder='Digite a Descrição'
          name='descricao'
          fullWidth
          {...register("descricao")}
          multiline
          rows={3}
          error={!!errors.descricao}
          helperText={getFormErrorMessage(errors, 'descricao')}
        />
      </Grid>

      {empresas &&
        <>
          <Grid item xs={12} md={4}>
            <Controller
              name='id_empresa'
              control={control}
              rules={{ required: true }}
              defaultValue={equipamento ? equipamento.empresa.id : ""}
              render={({ field: { ref, onChange, ...field } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  sx={{ mt: 1 }}
                  label="Empresa"
                  defaultValue={equipamento ? equipamento.empresa.id : ""}
                  onChange={(e) => {
                    setSelectedEmpresaId(e.target.value);
                    setValue('id_empresa', e.target.value, { shouldDirty: true })
                    setValue('id_ponto_entrega', '', { shouldDirty: true })
                    if (e.target.value) {
                      clearErrors('id_empresa');
                    }
                  }}
                  error={!!errors.id_empresa}
                  helperText={getFormErrorMessage(errors, 'id_empresa')}
                >
                  {empresas.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nomeFantasia}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {pontosEntrega &&
            <Grid item xs={12} md={4}>
              <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                <Controller
                  name='id_ponto_entrega'
                  control={control}
                  rules={{ required: true }}
                  defaultValue={equipamento ? equipamento.pontoEntrega.id : ""}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      sx={{ mt: 1 }}
                      label="Ponto de Entrega"
                      disabled={!selectedEmpresaId}
                      onChange={(e) => {
                        setValue('id_ponto_entrega', e.target.value, { shouldDirty: true })
                        if (e.target.value) {
                          clearErrors('id_ponto_entrega');
                        }
                      }}
                      error={!!errors.id_ponto_entrega}
                      helperText={getFormErrorMessage(errors, 'id_ponto_entrega')}
                    >
                      {pontosEntrega.length > 0 ? (
                        // Separar itens habilitados e desabilitados
                        [
                          ...pontosEntrega.filter(option => option.status !== 0).map(option => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.nome}
                            </MenuItem>
                          )),
                          ...pontosEntrega.filter(option => option.status === 0).map(option => (
                            <MenuItem key={option.id} value={option.id} disabled>
                              {option.nome} - (Inativo)
                            </MenuItem>
                          ))
                        ]
                      ) : (
                        <MenuItem disabled>Nenhum disponível</MenuItem>
                      )}
                    </TextField>
                  )}
                />
              </TooltipSelectDisabled>
            </Grid>
          }
        </>
      }

      <Grid item xs={12} md={4}>
        <TextField
          select
          label="Tipo de Contrato"
          name='tipo_contrato'
          fullWidth
          defaultValue={equipamento ? equipamento.tipo_contrato : 'locacao'}
          error={!!errors.tipo_contrato}
          helperText={getFormErrorMessage(errors, 'tipo_contrato')}
          {...register("tipo_contrato", { required: true })}
          sx={{ mt: 1 }}
        >
          {equipmentContractTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}