import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState, useEffect } from 'react';

function ConfirmDialog({ title, description, state, handleClose, goAction, buttonColor = "error", goActionText = "OK" }) {
    const [dialog, exibiDialog] = useState(false)

    useEffect(() => {
        if (state) {
            exibiDialog(
                <Dialog
                    open={state}
                    onClose={() => handleClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {description}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose(false)} variant='outlined'>Cancelar</Button>
                        <Button color={buttonColor} onClick={() => goAction(true)} autoFocus variant="contained">
                            {goActionText}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        } else {
            exibiDialog('')
        }
    }, [state, description, goAction, handleClose, title]);

    return (<>
        {dialog}
    </>);
}

export default ConfirmDialog;