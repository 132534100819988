import { Routes, Route, Navigate } from 'react-router-dom';
import { GeneratePassword } from '../components/screens/GeneratePassword';

export function FirstAccessRoutes() {
  return (
    <Routes>
      <Route path='/gerar-senha' Component={GeneratePassword} />

      {/* Rota de Fallback, faz o redirecionamento de rotas indisponíveis */}
      <Route path='*' element={<Navigate to="/gerar-senha" />} />
    </Routes>
  )
}