import axios from 'axios';
import { useAppContext } from './contexts/AppContext';
import { Routes } from './routes';
import { displayAlertMessageStorage, redirectUnauthorizedUser } from './configs/functions';
import { urlLogin, urlValidateToken } from './constants/endpoints';
import Footer from './components/common/Footer';
import { useCommonItems } from './contexts/CommonItensProvider';

function App() {
  const { dadosUsuario, token, signOut } = useAppContext();
  const { exibirAlerta } = useCommonItems();

  const isUserFirstAccess = dadosUsuario?.primeiro_acesso || false;

  // intercepta o Axios para adicao do token e afins
  axios.interceptors.request.use(async (config) => {
    config.baseURL = process.env.REACT_APP_API;
    // config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';

    // Check if the current request is for unauthorized routes or validateToken
    if (!config.url.includes(urlValidateToken) && config.url !== urlLogin && dadosUsuario && dadosUsuario.expires_at) {
      // expires_at + 10 min
      const expiresAtUTC = new Date(dadosUsuario.expires_at);
      expiresAtUTC.setMinutes(expiresAtUTC.getMinutes() + 10);

      const date = new Date();
      const currentUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

      // difference in seconds
      const differenceTimeInSeconds = (expiresAtUTC - currentUTC) / 1000;

      // verificar no dadosUsuario.expires_at se o token irá expirar em menos de 1 min,
      // caso sim, acessar a rota validateToken para renovar o token
      if (differenceTimeInSeconds < 60 && differenceTimeInSeconds > 0) {
        try {
          const res = await axios.get(`${urlValidateToken}?aumentarTempo=1`);
          // console.log('urlValidateToken ~ res:', res);
        } catch (err) {
          console.log('urlValidateToken ERROR ~ err:', err);
          signOut();
        }
      }
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    if (process.env.REACT_APP_ENABLE_API_DELAY === 'true') {
      await new Promise((resolve) =>
        setTimeout(resolve, 3000),
        // setTimeout(resolve, Math.round(Math.random() * 3000)), // random delay
      );
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(
    response => response,
    async function (error) {
      if (!error.response || error.code === "ERR_NETWORK") {
        exibirAlerta('Ops, ocorreu um erro', 'Verifique sua conexão com a internet', 'error');
        return Promise.reject(error);
      }

      // mover lógica de refreshToken para interceptors.response
      // quando lógica estiver no response, é possível montar uma fila de requisições
      // e quando o token for renovado, executar as requisições pendentes

      if (error.response.status === 401) {
        if (error.response.data.message === "Usuário não autenticado") {
          await signOut();
          displayAlertMessageStorage('Sua sessão expirou. Faça login novamente.');
        } else {
          const message = error.response.data.message || 'Usuário não autorizado';
          redirectUnauthorizedUser();
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <Routes userToken={token} isFirstAccess={isUserFirstAccess} />
      <Footer />
    </>
  );
}

export default App;
