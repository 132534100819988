import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../../common/CommonDialogActionsForm';
import { manipulaFuncao } from '../configs/functions';
import { fillInputErrors, getFormErrorMessage } from '../../../../../configs/functions';

export function FunctionForm({ open, setOpen, selectedFunction, empresas }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);

    const isEditAction = !!selectedFunction;

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const onSubmit = async (data) => {
        let formDataToSend = {};

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedFunction.funcoes_id[0];
        } else {
            formDataToSend = data;
        }

        try {
            setIsLoading(true);
            exibirDialog('Salvando. Aguarde...');
            const res = await manipulaFuncao(formDataToSend, isEditAction);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao cadastrar Função.";
            if (error.response) {
                errorMessage = error.response.data.message
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            const defaultValues = {
                id: selectedFunction ? selectedFunction.funcoes_id[0] : '',
                nome: selectedFunction ? selectedFunction.nome : '',
                status: selectedFunction ? selectedFunction.status[0] : 1,
                descricao: selectedFunction ? selectedFunction.descricao : '',
                observacao: selectedFunction ? selectedFunction.observacao : '',
                cod_externo: selectedFunction ? selectedFunction.cod_externo : '',
                id_empresa: selectedFunction ? selectedFunction.empresas_id[0] : '',
            };
            reset(defaultValues);
        } else {
            reset();
        }
    }, [open, reset, selectedFunction]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Função <strong>{selectedFunction.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar nova Função</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um nova Função.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Título'
                                        placeholder='Digite o nome da Função'
                                        name='nome'
                                        fullWidth
                                        {...register("nome", { required: true })}
                                        error={!!errors.nome}
                                        helperText={getFormErrorMessage(errors, 'nome')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Código Externo'
                                        placeholder='Digite o código externo'
                                        name='cod_externo'
                                        fullWidth
                                        {...register("cod_externo", { required: false })}
                                        error={!!errors.cod_externo}
                                        helperText={getFormErrorMessage(errors, 'cod_externo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                {empresas &&
                                    <Grid container item xs={12} mr={2}>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name='id_empresa'
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={selectedFunction ? selectedFunction.empresas_id[0] : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Empresa"
                                                        onChange={(e) => {
                                                            setValue('id_empresa', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) clearErrors("id_empresa");
                                                        }}
                                                        error={!!errors.id_empresa}
                                                        helperText={getFormErrorMessage(errors, 'id_empresa')}
                                                    >
                                                        {empresas.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.nomeFantasia}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Observação'
                                        placeholder='Digite a Observação'
                                        name='observacao'
                                        fullWidth
                                        {...register("observacao")}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!errors.observacao}
                                        helperText={getFormErrorMessage(errors, 'observacao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}