import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import { atualizaArea, deletaArea, listaAreas } from './configs/functions';
import { BlueprintIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { AreaForm } from './AreaForm';
import { useAppContext } from '../../../../contexts/AppContext';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';

const AreaRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status[0]);
    const [openDialog, setOpenDialog] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_areas", "update_areas"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaArea(data.areas_id[0], { status: !active })
            if (res) {
                const message = active ? 'Área inativada com sucesso' : 'Área ativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.areas_id[0], !data.status[0])
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
        }
    }

    const deleteCategory = () => {
        const id = data.areas_id[0];
        deletaArea(id)
            .then((response) => {
                const message = response.data.message ?? 'Área inativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                }}
            >
                <TableCell sx={{ flex: 2, border: 0 }}>{data.nome}</TableCell>
                <TableCell sx={{ flex: 1, border: 0 }}>{data.cod_externo}</TableCell>
                <TableCell sx={{ flex: 3, border: 0 }}>{data.descricao}</TableCell>

                <TableCell sx={{ flex: 1, border: 0 }}>
                    <Switch checked={!!active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_areas", "update_areas"])} />
                </TableCell>

                <TableCell align='right' sx={{ flex: 1, border: 0, minWidth: 100 }}>
                    {hasPermission(["admin", "admin_areas", "update_areas"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>}

                    {hasPermission(["admin", "admin_areas", "delete_areas"]) &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>}
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir esta área?'}
                title={'Excluir Área'}
                goAction={deleteCategory}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

function Areas() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [areas, setAreas] = useState([]);
    const [openForm, setOpenForm] = useState(false)
    const [selectedArea, setSelectedArea] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

    const carregaAreas = async () => {
        if (!selectedEmpresaId) return;
        const timer = setTimeout(() => setIsLoading(true), 500);
        try {
            const filterAllPaginated = {
                id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId],
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaAreas(filterAllPaginated);
            setNumTotalItems(res?.data.numero_total);
            setAreas(res?.data.data);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar as Areas', 'error');
        } finally {
            clearTimeout(timer);
            setIsLoading(false);
        }
    }

    function handleEditArea(item) {
        setSelectedArea(item);
        setOpenForm(true);
    }

    const updateCategoryStatus = (categoryId, status) => setAreas(
        areas.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            if (selectedEmpresaId) {
                carregaAreas();
            }
            setSelectedArea(null);
        }
    }, [openForm, selectedEmpresaId, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_areas", "view_areas"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);
                setIsLoading(true);

                if (!selectedEmpresaIdSession) {
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }
            })).finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (<>
        <Helmet title="Áreas" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao >
                    <Typography variant='span'>Gerenciamento</Typography>
                    <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
                    <Typography variant='span'>Áreas</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<BlueprintIcon fontSize='large' />}
                    title={'Áreas'}
                    description='Administre as áreas disponíveis das suas empresas.'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setPage(0);
                        }}
                    />

                    {hasPermission(["admin", "admin_areas", "create_areas"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar nova área
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Áreas">
                        <TableHead>
                            <TableRow sx={{ display: 'flex' }}>
                                <TableCell sx={{ flex: 2 }}>Área</TableCell>
                                <TableCell sx={{ flex: 1 }}>Cód. Externo</TableCell>
                                <TableCell sx={{ flex: 3 }}>Descrição</TableCell>
                                <TableCell sx={{ flex: 1 }}>Status</TableCell>
                                <TableCell sx={{ flex: 1, minWidth: 110 }} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : areas && areas?.length > 0
                                    ? areas.map((item) =>
                                        <AreaRow
                                            key={item.areas_id[0]}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditArea}
                                            handleUpdateStatus={updateCategoryStatus}
                                            updateData={carregaAreas}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>

        </Grid>

        <AreaForm open={openForm} setOpen={setOpenForm} selectedArea={selectedArea} />
    </>);
}

export default Areas;