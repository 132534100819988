import dayjs from 'dayjs';
import { List, ListItem, ListItemText } from '@mui/material';

export function formatKey(key) {
  if (typeof key === 'string') {
    if (key === 'dt_ponto_alerta') {
      return 'Data do Ponto de Alerta';
    } else if (key === 'dt_prox_retirada') {
      return 'Data da Próxima Retirada';
    }
    return key.charAt(0).toUpperCase() + key.slice(1);
  } else {
    return key;
  }
}

export function renderValue(value) {
  if (Array.isArray(value)) {
    return (
      <List dense sx={{ listStyleType: 'circle', paddingTop: 0.2, paddingBottom: 0.5, paddingLeft: 2.2 }}>
        {value.map((item, index) => (
          <ListItem key={index} sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
            <ListItemText primary={item} sx={{ margin: 0 }} />
          </ListItem>
        ))}
      </List>
    );
  } else if (typeof value === 'object' && value !== null) {
    return (
      <List dense sx={{ listStyleType: 'circle', paddingTop: 0.2, paddingBottom: 0.5, paddingLeft: 2.2 }}>
        {Object.entries(value).map(([subKey, subValue]) => {
          if (subKey !== 'id') {
            return (
              <ListItem key={subKey} sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                <ListItemText
                  primary={
                    <>
                      <span style={{ fontWeight: 600 }}>{formatKey(subKey)}:</span> {subValue}
                    </>
                  }
                  sx={{ margin: 0 }}
                />
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );
  } else if (dayjs(value, 'YYYY-MM-DD', true).isValid() || dayjs(value).isValid()) {
    return dayjs(value).format('DD/MM/YYYY');
  } else {
    return value;
  }
}
