import React, { useState } from 'react';
import { CheckCircleOutlineTwoTone, Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Button, CircularProgress, Divider } from '@mui/material';
import { atualizaStatusRetiradaExtra } from '../functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';

export function ApproveRetiradaExtraModal({ open, setOpen, infoRetirada }) {
  const { exibirAlerta } = useCommonItems();
  const [isLoading, setIsLoading] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  async function handleUpdateStatus(status) {
    setIsLoading(true);
    try {
      await atualizaStatusRetiradaExtra(infoRetirada.id, status);
      exibirAlerta('Sucesso', `Retirada ${status ? 'aceita' : 'recusada'} com sucesso`, 'success');
    } catch (error) {
      // console.log('Erro ao atualizar status:', error);
      const errorMessage = error?.response?.data?.error || 'Erro ao atualizar Retirada';
      exibirAlerta('Ops!', errorMessage, 'error');
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      {infoRetirada && (
        <>
          <DialogTitle>Solicitação de Retirada</DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <List sx={{ pt: 0, px: 3 }}>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={"Funcionário:"}
                    secondary={infoRetirada?.pessoa?.nome ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary={"Produto:"}
                    secondary={`${infoRetirada?.produto?.nome ?? "Não Informado(a)"} - ${infoRetirada?.produto?.ca ?? "Não Informado(a)"}`}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary={"Justificativa:"}
                    secondary={infoRetirada?.justificativaOcorrencia?.nome ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={"Equipamento:"}
                      secondary={infoRetirada?.equipamento?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Responsável Entrega:"
                      secondary={infoRetirada?.responsavel?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary="Empresa:"
                    secondary={infoRetirada?.empresa?.nomeFantasia ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Grupo:"
                      secondary={infoRetirada?.grupo?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Função:"
                      secondary={infoRetirada?.funcao?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Área:"
                      secondary={infoRetirada?.pontoEntrega ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary="Solicitação criada em:"
                    secondary={infoRetirada?.createdAt ?? "Não Informado(a)"}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </List>

          <Grid container spacing={2} sx={{ px: 3, pb: 2 }}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleClose}
                >
                  Voltar
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => handleUpdateStatus('negado')}
                  disabled={isLoading || infoRetirada?.status !== 'pendente'}
                  startIcon={<Close />}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Recusar'}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={() => handleUpdateStatus('aceito')}
                  disabled={isLoading || infoRetirada?.status !== 'pendente'}
                  startIcon={<CheckCircleOutlineTwoTone />}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Aceitar'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Dialog>
  );
}
