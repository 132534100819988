import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import Theme from './configs/theme';
import { AppProvider } from './contexts/AppContext';
import { CommonItensProvider } from './contexts/CommonItensProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ptBR } from '@mui/x-date-pickers/locales';
import { queryClient } from './lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const apiUrl = process.env.REACT_APP_API;
const consultaCaUrl = process.env.REACT_APP_CONSULTA_CA_URL;
const consultaCepUrl = process.env.REACT_APP_CONSULTA_CEP_URL;
const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
const googleFontsUrl = "https://fonts.gstatic.com";
const googleFontsStylesheet = "https://fonts.googleapis.com";

const csp = `
  default-src 'self';
  script-src 'self' 'unsafe-inline' 'unsafe-eval';
  style-src 'self' 'unsafe-inline' ${googleFontsStylesheet};
  img-src 'self' ${s3BucketUrl} data: blob:;
  font-src 'self' data: ${googleFontsUrl};
  connect-src 'self' ${apiUrl} ${consultaCaUrl} ${consultaCepUrl};
`;

// add
// report-uri /csp-violation-report-endpoint;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet
        defaultTitle='STW - SAFE TO WORK | Sistema de Gestão de Segurança do Trabalho'
        titleTemplate='%s | STW - SAFE TO WORK | Sistema de Gestão de Segurança do Trabalho'
      >
        <meta http-equiv="Content-Security-Policy" content={csp} />
      </Helmet>
      <AppProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={Theme}>
            <CssBaseline>
              <CommonItensProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </LocalizationProvider>
              </CommonItensProvider>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
