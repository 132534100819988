import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { listaCentroCustos, manipulaArea } from "./configs/functions"
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { listaEmpresas } from '../companies/configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';

export function AreaForm({ open, setOpen, selectedArea }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [id_empresas, setIdEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [idCentrosCustos, setIdCentrosCustos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isEditAction = !!selectedArea;

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const onSubmit = async (data) => {
        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            // se for edição, pegar o id área (vem como array)
            formDataToSend.id_area = selectedArea.areas_id[0];

            formDataToSend.id_empresas = Array.isArray(data.id_empresas) ? data.id_empresas : [data.id_empresas];

            if (dirtyData?.id_empresas) {
                formDataToSend.id_empresas = Array.isArray(dirtyData.id_empresas) ? dirtyData.id_empresas : [dirtyData.id_empresas];
            }
        } else {
            delete data.id_area;
            formDataToSend = data;
            formDataToSend.id_empresas = Array.isArray(data.id_empresas) ? data.id_empresas : [data.id_empresas];
        }

        manipulaArea(formDataToSend, isEditAction)
            .then((res) => {
                exibirAlerta('Sucesso', res.data.message, 'success');
                setOpen(false)
            })
            .catch((error) => {
                console.error(error);
                let errorMessage = "Erro ao cadastrar área";
                if (error.response) {
                    errorMessage = error.response.data.message
                    fillInputErrors(error, setError);
                }
                exibirAlerta('Erro', errorMessage, 'error');
            }).finally(() => {
                setIsLoading(false);
                handleCloseDialog();
            });
    };

    function getCentrosCustos(filterByEmpresaId) {
        listaCentroCustos(filterByEmpresaId)
            .then((response) => setIdCentrosCustos(response?.data.data))
            .catch((error) => {
                let errorMessage = "Erro ao carregar centros de custos";
                if (error.response.data.error) {
                    errorMessage = error.response.data.error;
                }
                exibirAlerta('Erro', errorMessage, 'error');
            });
    }

    // useEffect(() => {
    //     if (!selectedEmpresaId) return;
    //     const filterByEmpresaId = { "id_empresas": [selectedEmpresaId] }
    //     getCentrosCustos(filterByEmpresaId);
    // }, [selectedEmpresaId]);

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            listaEmpresas().then((response => setIdEmpresas(response?.data.data)));

            // set default values to form
            const defaultValues = {
                id_area: selectedArea ? selectedArea.areas_id[0] : '',
                nome: selectedArea ? selectedArea.nome : '',
                cod_externo: selectedArea ? selectedArea.cod_externo : '',
                status: selectedArea ? selectedArea.status : 1,
                descricao: selectedArea ? selectedArea.descricao : '',
                observacao: selectedArea ? selectedArea.observacao : '',
                id_centroCusto: selectedArea ? selectedArea.centrocustos_id[0] : '',
                id_empresas: selectedArea ? selectedArea.empresas_id[0] : [],
            };
            if (selectedArea) {
                setSelectedEmpresaId(selectedArea.empresas_id[0]);
            }
            reset(defaultValues);
        } else {
            // limpa o formulário ao sair
            reset();
            setSelectedEmpresaId(null);
        }
    }, [open, reset]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando área <strong>{selectedArea.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar nova área</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar uma nova área.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Nome'
                                        placeholder='Digite o nome da Área'
                                        name='nome'
                                        fullWidth
                                        {...register("nome", { required: true })}
                                        error={!!errors.nome}
                                        helperText={getFormErrorMessage(errors, 'nome')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Código Externo'
                                        placeholder='Digite o código externo'
                                        name='cod_externo'
                                        fullWidth
                                        {...register("cod_externo", { required: false })}
                                        error={!!errors.cod_externo}
                                        helperText={getFormErrorMessage(errors, 'cod_externo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='id_empresas'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={selectedArea ? selectedArea.empresas_id[0] : ""}
                                        render={({ field: { ref, onChange, ...field } }) => (
                                            <TextField
                                                {...field}
                                                select
                                                fullWidth
                                                sx={{ mt: 1 }}
                                                label="Empresa"
                                                onChange={(e) => {
                                                    setSelectedEmpresaId(e.target.value);
                                                    setValue('id_empresas', e.target.value, { shouldDirty: true })
                                                    setValue('id_centroCusto', '', { shouldDirty: true })
                                                    if (e.target.value) clearErrors("id_empresas");
                                                }}
                                                error={!!errors.id_empresas}
                                                helperText={getFormErrorMessage(errors, 'id_empresas')}
                                            >
                                                {id_empresas.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nomeFantasia}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </Grid>

                                {/* {idCentrosCustos &&
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled isDisabled={selectedEmpresaId || (selectedArea && selectedArea.centrocustos_id[0])}>
                                            <Controller
                                                name='id_centroCusto'
                                                control={control}
                                                defaultValue={selectedArea ? selectedArea.centrocustos_id[0] : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Centro de Custo"
                                                        disabled={!selectedEmpresaId || (selectedArea && !selectedArea.centrocustos_id[0])}
                                                        onChange={(e) => {
                                                            setValue('id_centroCusto', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) {
                                                                clearErrors('id_centroCusto');
                                                            }
                                                        }}
                                                        error={!!errors.id_centroCusto}
                                                        helperText={getFormErrorMessage(errors, 'id_centroCusto')}
                                                    >
                                                        {idCentrosCustos.length > 0 ? (
                                                            // Separar itens habilitados e desabilitados
                                                            [
                                                                ...idCentrosCustos.filter(option => option.status !== 0).map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.nome}
                                                                    </MenuItem>
                                                                )),
                                                                ...idCentrosCustos.filter(option => option.status === 0).map(option => (
                                                                    <MenuItem key={option.id} value={option.id} disabled>
                                                                        {option.nome} - (Inativo)
                                                                    </MenuItem>
                                                                ))
                                                            ]
                                                        ) : (
                                                            <MenuItem disabled>Nenhum disponível</MenuItem>
                                                        )}
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                } */}

                                {/* {isEditAction ?
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name='id_empresas'
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={selectedArea ? selectedArea.empresas_id[0] : ""}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    fullWidth
                                                    sx={{ mt: 1 }}
                                                    label="Empresa"
                                                    onChange={(e) => {
                                                        setValue('id_empresas', e.target.value, { shouldDirty: true })
                                                        if (e.target.value) clearErrors("id_empresas");
                                                    }}
                                                    error={!!errors.id_empresas}
                                                    helperText={getFormErrorMessage(errors, 'id_empresas')}
                                                >
                                                    {id_empresas.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.nomeFantasia}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                    :
                                    <Grid container item>
                                        <Grid item xs={12} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                            <Stack direction='row' spacing={1} alignItems='center' flexWrap="wrap">
                                                <Typography color={errors.id_empresas && 'error'}>
                                                    Empresas Relacionadas
                                                </Typography>

                                                <Typography component="span" color={grey[700]}>
                                                    - Selecione quais empresas essa área irá pertencer
                                                </Typography>
                                            </Stack>

                                            {errors.id_empresas && (
                                                <Typography variant="subtitle2" component="caption" color='error'>
                                                    - {getFormErrorMessage(errors, 'id_empresas')}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid container py={1} item xs={12}>
                                            <Controller
                                                name='id_empresas'
                                                control={control}
                                                defaultValue={[]}
                                                rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma empresa." }}
                                                render={({ field: { onChange, value } }) => (
                                                    id_empresas.map((empresa) => (
                                                        <Grid item xs={12} md={6} key={empresa.id}>
                                                            <FormControlLabel
                                                                label={
                                                                    <Typography>
                                                                        {empresa.nomeFantasia}

                                                                        <Typography component="span" style={{ color: '#7a7a7a', marginLeft: '0.2rem' }}>
                                                                            ({empresa.tipo.tituloTipo})
                                                                        </Typography>
                                                                    </Typography>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked={selectedArea ? selectedArea?.empresas_id.includes(empresa.id) : id_empresas.length == 1}
                                                                        onChange={(e, isChecked) => {
                                                                            let updatedEmpresas = [...value];
                                                                            if (isChecked) {
                                                                                updatedEmpresas.push(empresa.id);
                                                                            } else {
                                                                                updatedEmpresas = updatedEmpresas.filter((id) => id !== empresa.id);
                                                                            }
                                                                            setValue('id_empresas', updatedEmpresas, { shouldDirty: true });
                                                                            onChange(updatedEmpresas);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ))
                                                )}
                                            />
                                        </Grid>

                                        <DialogContentText sx={{ mt: 1, color: grey[700] }}>
                                            Será criada uma nova área para cada empresa selecionada.
                                        </DialogContentText>
                                    </Grid>
                                } */}

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Observação'
                                        placeholder='Digite a Observação'
                                        name='observacao'
                                        fullWidth
                                        {...register("observacao")}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!errors.observacao}
                                        helperText={getFormErrorMessage(errors, 'observacao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}