import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  Table,
  IconButton,
} from '@mui/material';
import { Add, Home } from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { EditIcon, ElectronicsIcon, TrashIcon, VendingMachineIcon, WacomTabletIcon } from '../../../common/Icons';
import { atualizaEquipamento, carregaEquipamentos, deletaEquipamento } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { MachineEquipmentForm } from './components/EquipmentForm';
import { EquipmentTypeDialog } from './components/EquipmentForm/EquipmentTypeDialog';
import { DeskEquipmentForm } from './components/DeskEquipmentForm';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import ConfirmDialog from '../../../common/ConfirmDialog';

const EquipmentRow = ({ data, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog }) => {
  return (
    <TableRow>
      <TableCell align="center">
        {data.tipo === 'maquina' ? <VendingMachineIcon /> : <WacomTabletIcon />}
      </TableCell>
      <TableCell>{data.nome}</TableCell>
      <TableCell>{data.empresa.nome}</TableCell>
      <TableCell>{data.descricao}</TableCell>
      <TableCell>
        <Switch checked={!!data.status} onChange={handleUpdateStatus} disabled={!hasPermission(['admin', 'admin_equipamentos', 'update_equipamentos'])} />
      </TableCell>
      <TableCell align="right" style={{ minWidth: '110px' }}>
        {hasPermission(['admin', 'admin_equipamentos', 'update_equipamentos']) && (
          <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
            <EditIcon />
          </IconButton>
        )}

        {hasPermission(['admin', 'admin_equipamentos', 'delete_equipamentos']) && (
          <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
            <TrashIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export function ListOfEquipment() {
  const queryClient = useQueryClient();
  const { exibirAlerta } = useCommonItems();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMachineForm, setOpenMachineForm] = useState(false);
  const [openDeskForm, setOpenDeskForm] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [openEquipmentTypeModal, setOpenEquipmentTypeModal] = useState(false);

  const queryKey = ['listaEquipamentos', page, rowsPerPage];

  const { data: equipamentosData, isLoading: isLoadingEquipamentos } = useQuery({
    queryKey,
    queryFn: () =>
      carregaEquipamentos({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      }),
  });

  const { data: empresasData } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: listaEmpresas,
    staleTime: Infinity,
  });

  const equipamentos = equipamentosData?.data?.data ?? [];
  const numTotalItems = equipamentosData?.data?.numero_total ?? 0;

  const handleUpdateStatus = useMutation({
    mutationFn: (data) => atualizaEquipamento(data.id, data.dataToUpdate),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      // get from cached data, descobrir forma de encontrar qual item foi atualizado.
    },
  });

  function handleOpenForm(equipmentType) {
    if (equipmentType) {
      switch (equipmentType) {
        case 'maquina':
          setOpenMachineForm(true);
          break;
        case 'balcao':
          setOpenDeskForm(true);
          break;
        default:
          exibirAlerta('Erro', 'Tipo de equipamento inválido', 'error');
          break;
      }
    }
  }

  const handleEdit = (equipamento) => {
    setSelectedEquipment(equipamento);
    handleOpenForm(equipamento.tipo);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedEquipment(item);
    setOpenDeleteDialog(true);
  };

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaEquipamento,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Equipamento excluído com sucesso';
      exibirAlerta('Sucesso', message, 'success');

      if (message === 'O equipamento indicado(a) possui relação com outros itens. Por isso foi inativado') {
        // como o equipamento não é excluído dependendo dos seus relacionamentos, 
        // nao é possível saber se excluiu ou se atualizou o status.
        queryClient.invalidateQueries(queryKey);
      } else {
        const cached = queryClient.getQueryData(queryKey);
        const updatedData = {
          ...cached,
          data: {
            ...cached.data,
            data: cached.data.data.filter((item) => item.id !== id),
          },
        };
        queryClient.setQueryData(queryKey, updatedData);
      }
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedEquipment(null);
    },
  });

  useEffect(() => {
    checkPermissionsAndRedirect(['admin', 'admin_equipamentos', 'list_equipamentos']);
  }, []);

  useEffect(() => {
    if (!openMachineForm && !openDeskForm) {
      setSelectedEquipment(null);
    }
  }, [openMachineForm, openDeskForm]);

  return (
    <>
      <Helmet title='Equipamentos' defer={false} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="end"
        spacing={3}
      >
        <Grid item xs={12} md={9}>
          <Breadcrumbs>
            <LinkNavegacao to="/"><Home fontSize="small" /></LinkNavegacao>
            <LinkNavegacao to="/epis">Gestão de EPI's</LinkNavegacao>
            <Typography variant='span'>Equipamentos</Typography>
          </Breadcrumbs>
          <PageTitle
            icon={<ElectronicsIcon fontSize='large' />}
            title="Equipamentos"
            description="Gerencie os equipamentos das empresas."
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography
            sx={{
              textAlign: {
                xs: 'center',
                md: 'right'
              }
            }}
          >
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setOpenEquipmentTypeModal(true)}
              disabled={!hasPermission(["admin", "admin_equipamentos", "create_equipamentos"])}
            >
              Cadastrar Equipamento
            </Button>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Equipamentos">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingEquipamentos ? (
                  <SkeletonTableRow
                    numCells={6}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                    firstCellIsIcon
                  />
                ) : equipamentos.length > 0 ? (
                  equipamentos.map((equipamento) => (
                    <EquipmentRow
                      key={equipamento.id}
                      data={equipamento}
                      handleOpenEditForm={handleEdit}
                      handleUpdateStatus={() => {
                        const dataSendUpdate = { id: equipamento.id, dataToUpdate: { status: !equipamento.status } }
                        handleUpdateStatus.mutateAsync(dataSendUpdate)
                      }}
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(equipamento)}
                    />
                  ))
                ) : (
                  <EmptyTableRow />
                )}
              </TableBody>
            </Table>

            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      {/* Dialog (Modal) para usuário selecionar na edição se irá cadastrar maquina ou balcão */}
      <EquipmentTypeDialog
        open={openEquipmentTypeModal}
        setOpen={setOpenEquipmentTypeModal}
        handleOpenModalEquipment={(equipmentType) => handleOpenForm(equipmentType)}
      />

      {/* Formulário para equipamento do tipo Maquina */}
      <MachineEquipmentForm
        open={openMachineForm}
        setOpen={setOpenMachineForm}
        equipamento={selectedEquipment}
        empresas={empresasData?.data?.data ?? []}
        queryKey={queryKey}
      />

      {/* Formulário para equipamento do tipo Balcão */}
      <DeskEquipmentForm
        open={openDeskForm}
        setOpen={setOpenDeskForm}
        equipamento={selectedEquipment}
        empresas={empresasData?.data?.data ?? []}
        queryKey={queryKey}
      />

      <ConfirmDialog
        title="Excluir Equipamento"
        description="Tem certeza que deseja excluir este equipamento?"
        goAction={() => handleDelete(selectedEquipment.id)}
        handleClose={setOpenDeleteDialog}
        state={openDeleteDialog}
      />
    </>
  );
}