import { React } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import {
    Assessment,
    NotificationsActive,
    SensorWindow,
    Summarize,
    School,
    Book,
    Festival
} from '@mui/icons-material';
import { LinkMenu } from '../../../../configs/functions';
import { useAppContext } from '../../../../contexts/AppContext';
import MenuTitle from '../../../common/MenuTitle';


function MenuTraining({ openMenuEpi, handleClick }) {

    const { setDrawerOpen } = useAppContext();

    return (<>
        <MenuTitle title="Treinamentos" icon={<School />} stateVar={openMenuEpi} fChangeState={() => { handleClick() }} />
        <Collapse in={openMenuEpi} timeout="auto" unmountOnExit>
            <List>
                <LinkMenu url='/treinamentos' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Assessment />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                </LinkMenu>

                <LinkMenu url='/treinamentos/treinamentos' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Book />
                        </ListItemIcon>
                        <ListItemText primary="Treinamentos" />
                    </ListItemButton>
                </LinkMenu>

                <LinkMenu url='/treinamentos/eventos' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Festival />
                        </ListItemIcon>
                        <ListItemText primary="Eventos" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/treinamentos/equipamentos' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <SensorWindow />
                        </ListItemIcon>
                        <ListItemText primary="Equipamentos" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/treinamentos/alertas' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <NotificationsActive />
                        </ListItemIcon>
                        <ListItemText primary="Alertas" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/treinamentos/relatorios' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Summarize />
                        </ListItemIcon>
                        <ListItemText primary="Relatórios" />
                    </ListItemButton>
                </LinkMenu>
            </List>
        </Collapse>

    </>);
}

export default MenuTraining;