import { createContext, useState, useContext } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [dadosUsuario, setDadosUsuario] = useState(
        localStorage.getItem("@STW:userData")
            ? JSON.parse(localStorage.getItem("@STW:userData"))
            : {}
    );
    const [activeModule, setActiveModule] = useState('epis');

    function changeActiveModule(module) {
        setActiveModule(module)
    }

    function updateDadosUsuarioLocalStorage(dados) {
        const userData = JSON.parse(localStorage.getItem("@STW:userData"));
        const newUserData = { ...userData, ...dados };
        localStorage.setItem("@STW:userData", JSON.stringify(newUserData));
        setDadosUsuario(newUserData);
    }

    function getEmpresaIdSession() {
        return parseInt(localStorage.getItem("@STW:idEmpresaSelected")) || null;
    }

    function setEmpresaIdSession(empresaId) {
        localStorage.setItem("@STW:idEmpresaSelected", empresaId);
    }

    async function signOut() {
        setDadosUsuario({});
        localStorage.clear();
        localStorage.removeItem("@STW:userData");
        window.location = '/';
    }

    return (
        <AppContext.Provider value={{
            drawerOpen,
            setDrawerOpen,
            activeModule,
            changeActiveModule,
            token: dadosUsuario.token || null,
            dadosUsuario,
            setDadosUsuario,
            signOut,
            updateDadosUsuarioLocalStorage,
            getEmpresaIdSession,
            setEmpresaIdSession
        }}>
            {children}
        </AppContext.Provider>
    )
}

const useAppContext = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('useContext must be used within an AppProvider');
    }

    return context;
};

export { AppProvider, useAppContext };