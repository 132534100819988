import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import { EditIcon, TrashIcon, CheckListIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { atualizaGHEs, deletaGHEs, listaGHEs } from './config/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import LinkNavegacao from '../../../common/Link';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import PageTitle from '../../../common/PageTitle';
import { Pagination } from '../../../common/Pagination';
import { FiltersButton } from '../../../common/FiltersButton';
import { useAppContext } from '../../../../contexts/AppContext';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { GHETableFilter } from './components/GHETableFilter';
import GHEForm from './GHEForm';

const GheRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status ? true : false);
    const [openDialog, setOpenDialog] = useState(false)

    const handleToggleStatus = async () => {
        if (!hasPermission(["admin", "admin_grupos", "update_grupos"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error');
            return;
        }

        try {
            const res = await atualizaGHEs(data.id, { status: !active });
            if (res) {
                const message = active ? 'Grupo desativado com sucesso' : 'Grupo ativado com sucesso';
                handleExibirAlerta('Sucesso', message, 'success');
                setActive(!active);
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error');
            console.error(error);
        }
    };

    const deleteGHE = () => {
        const id = data.id;

        deletaGHEs(id)
            .then((response) => {
                const message = response.data.message
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })
        setOpenDialog(false)
    }

    return (
        <>
            <TableRow key={data.id}>
                <TableCell>{data.nome}</TableCell>
                <TableCell>{data.cod_externo}</TableCell>
                <TableCell sx={{
                    maxWidth: 450,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>{data.observacao}</TableCell>

                <TableCell>
                    <Switch checked={!!active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_grupos", "update_grupos"])} />
                </TableCell>

                <TableCell align='right' sx={{ minWidth: 110 }}>
                    {hasPermission(["admin", "admin_grupos", "update_grupos"]) && (
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    {hasPermission(["admin", "admin_grupos", "delete_grupos"]) && (
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir este grupo?'}
                title={'Excluir Grupo'}
                goAction={deleteGHE}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

function ListOfGHEs() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    const [searchParams, setSearchParams] = useSearchParams();

    // estados para controle de paginacao
    const page = parseInt(searchParams.get('page') || 1) - 1;
    const rowsPerPage = parseInt(searchParams.get('perPage') || 10);

    const [numTotalItems, setNumTotalItems] = useState(0);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const [ghes, setGhes] = useState(null);
    const [openForm, setOpenForm] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [empresas, setEmpresas] = useState([]);
    const [selectedGHE, setSelectedGHE] = useState(null);

    const loadEmpresas = useCallback(async () => {
        try {
            const response = await listaEmpresas();
            const empresasData = response?.data.data || [];
            setEmpresas(empresasData);

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);

            setSearchParams((state) => {
                state.set('page', String(page + 1));
                state.set('perPage', String(rowsPerPage));
                state.set('id_empresa', String(selectedEmpresaId));

                return state;
            });
        } catch (error) {
            console.log('Failed to load empresas:', error);
            exibirAlerta('Erro', 'Erro ao carregar empresas.', 'error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const carregaGhes = useCallback(async (queryFilters) => {
        if (!queryFilters?.id_empresa) {
            return;
        }

        try {
            setIsLoading(true);
            const paginationFilter = {
                limit: rowsPerPage,
                offset: page * rowsPerPage,
                ...queryFilters,
            };

            setEmpresaIdSession(queryFilters.id_empresa);

            const res = await listaGHEs(paginationFilter);
            setGhes(res?.data.data);
            setNumTotalItems(res?.data.numero_total);
        } catch (error) {
            console.error('Erro ao carregar GHEs', error);
            exibirAlerta('Erro', 'Erro ao carregar GHEs', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [exibirAlerta, page, rowsPerPage, setEmpresaIdSession]);

    function handleEditGHE(item) {
        setSelectedGHE(item);
        setOpenForm(true);
    }

    const updateStatus = (id, status) => setGhes(
        ghes.map((item) => {
            if (item.id === id) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (openForm) return;
        setSelectedGHE(null);

        const queryFilters = { ...Object.fromEntries(searchParams) }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        carregaGhes(tableFilters);

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);
        setActiveFilters(activeFilters);
    }, [openForm, searchParams, carregaGhes]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_grupos", "list_grupos"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        loadEmpresas();
    }, [loadEmpresas]);

    return (<>
        <Helmet title='Grupos Homogêneos de Exposição' defer={false} />

        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
                    <LinkNavegacao to="/epis">Gestão de EPI"s</LinkNavegacao>
                    <LinkNavegacao to="/epis/diretrizes">Diretrizes</LinkNavegacao>
                    <Typography variant="span">Grupos Homogêneos de Exposição</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<CheckListIcon fontSize='large' />}
                    title={'Grupos Homogêneos de Exposição'}
                    description='Administre as Grupos Homogêneos de Exposição das suas empresas.'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent='space-between' alignItems='center'>
                    <FiltersButton
                        isOpenFilters={isOpenFilters}
                        setIsOpenFilters={setIsOpenFilters}
                        activeFilters={activeFilters}
                    />

                    {hasPermission(["admin", "admin_grupos", "create_grupos"]) &&
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => setOpenForm(true)}
                        >
                            Cadastrar novo GHE
                        </Button>
                    }
                </Stack>
            </Grid>

            <GHETableFilter
                isOpenFilters={isOpenFilters}
                isLoadingList={isLoading}
                empresas={empresas}
            />

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Grupos de Permissão">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Cód. Externo</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : ghes && ghes?.length > 0
                                    ? ghes.map((item) =>
                                        <GheRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditGHE}
                                            handleUpdateStatus={updateStatus}
                                            updateData={() => {
                                                carregaGhes({ ...Object.fromEntries(searchParams) })
                                            }}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <Pagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <GHEForm
            open={openForm}
            setOpen={setOpenForm}
            selectedGHE={selectedGHE}
        />
    </>);
}

export default ListOfGHEs;