import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Home, Restore, Search } from '@mui/icons-material';
import { ManualIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, hasPermission, renderDay, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { DevolucaoForm } from './DevolucaoForm';
import { getRetiradas, listaProdutos } from './configs/functions';

const RetiradaRow = ({ data, handleOpenEditForm }) => {
  return (
    <TableRow
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <TableCell sx={{ flex: 2, border: 0 }}>{data.codigo}</TableCell>
      <TableCell sx={{ flex: 2, border: 0 }}>{data.pessoa.nome} - {data.pessoa.matricula}</TableCell>
      <TableCell sx={{ flex: 3, border: 0 }}>{data.quantidadeProduto}x - {data.produto.nome}</TableCell>
      <TableCell sx={{ flex: 2, border: 0 }}>{data.equipamento.nome}</TableCell>
      <TableCell sx={{ flex: 2, border: 0 }}>{data.createdAt}</TableCell>
      <TableCell sx={{ flex: 1, border: 0 }} align='right'>
        {hasPermission(["admin", "admin_retiradas", "update_retiradas"]) &&
          <Tooltip title={data.isDevolucao ? 'Devolução já realizada' : 'Realizar Devolução'} arrow>
            <span>
              <IconButton
                aria-label="Abrir Devolução"
                onClick={() => handleOpenEditForm(data)}
                disabled={data.isDevolucao}
                sx={{
                  filter: data.isDevolucao ? 'brightness(0.9) grayscale(1)' : 'none',
                  '&:hover': {
                    filter: data.isDevolucao ? 'brightness(0.5) grayscale(1)' : 'brightness(0.8)',
                  }
                }}
              >
                <ManualIcon fontSize='large' />
              </IconButton>
            </span>
          </Tooltip>
        }
      </TableCell>
    </TableRow>
  )
}

export function DevolucaoRetiradas() {
  const { exibirAlerta } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [retiradas, setRetiradas] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [colaboradores, setColaboradores] = useState([]);
  const [produtos, setProdutos] = useState([]);

  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanyData, setIsLoadingCompanyData] = useState(false);

  const [openForm, setOpenForm] = useState(false);

  const {
    handleSubmit, setValue, getValues, setError, clearErrors, reset, control,
    formState: { errors },
  } = useForm();

  const getMatriculaFromId = (id_pessoa, colaboradores) => {
    return id_pessoa === 'all' ? null : colaboradores.find(c => c.id === id_pessoa)?.matricula;
  };

  const listaColaboradores = async (empresaId) => {
    try {
      const response = await listaUsuariosFilter({ id_empresa: empresaId });
      setColaboradores(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Colaboradores', '', 'error');
    }
  }

  const carregaProdutos = async (empresaId) => {
    try {
      const res = await listaProdutos({ id_empresa: empresaId });
      setProdutos(res?.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Produtos', '', 'error');
    }
  }

  async function carregaRetiradas(id_empresa, data_inicio, data_fim, id_pessoa, id_produto) {
    if (!id_empresa) return;

    try {
      setIsLoading(true);

      const filter = {
        id_empresas: Array.isArray(id_empresa) ? id_empresa : [id_empresa],
        data_inicio: dayjs(data_inicio).format('YYYY-MM-DD'),
        data_fim: dayjs(data_fim).format('YYYY-MM-DD'),
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };

      if (id_pessoa) {
        filter.matricula = getMatriculaFromId(id_pessoa.id, colaboradores);
      }

      if (id_produto && id_produto?.id !== 'all') {
        filter.id_produto = id_produto.id;
      }

      const res = await getRetiradas(filter);
      setRetiradas(res.data.data);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.error(error);
      exibirAlerta('Erro', 'Erro ao carregar Retiradas', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  function clearFilters() {
    const defaultValues = {
      id_empresa: getValues('id_empresa'),
      id_pessoa: 'all',
      id_produto: 'all',
      data_inicio: dayjs().subtract(1, 'month'),
      data_fim: dayjs(),
    };
    reset(defaultValues);
    carregaRetiradas(defaultValues.id_empresa, defaultValues.data_inicio, defaultValues.data_fim, defaultValues.id_pessoa, defaultValues.id_produto);
  }

  const onSubmit = async (data) => {
    carregaRetiradas(data.id_empresa, data.data_inicio, data.data_fim, data.id_pessoa, data.id_produto);
  };

  function handleEdit(item) {
    setSelectedItem(item);
    setOpenForm(true);
  }

  async function getDataEmpresa(empresaId) {
    setIsLoadingCompanyData(true);
    await listaColaboradores(empresaId);
    await carregaProdutos(empresaId);
    setIsLoadingCompanyData(false);
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_retiradas", "list_retiradas"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    let defaultEmpresaId = null;
    const selectedEmpresaIdSession = getEmpresaIdSession();
    if (selectedEmpresaIdSession) {
      defaultEmpresaId = selectedEmpresaIdSession;
      setSelectedEmpresaId(selectedEmpresaIdSession);
    }

    listaEmpresas().then((response => {
      const empresasData = response?.data.data;
      setEmpresas(empresasData);

      if (!selectedEmpresaIdSession) {
        defaultEmpresaId = empresasData[0].id;
        setSelectedEmpresaId(defaultEmpresaId);
        setEmpresaIdSession(defaultEmpresaId);
      }

      getDataEmpresa(defaultEmpresaId);

      const defaultValues = {
        id_empresa: defaultEmpresaId,
        id_pessoa: 'all',
        id_produto: 'all',
        data_inicio: dayjs().subtract(1, 'month'),
        data_fim: dayjs(),
      };
      reset(defaultValues);
      carregaRetiradas(defaultValues.id_empresa, defaultValues.data_inicio, defaultValues.data_fim, defaultValues.id_pessoa, defaultValues.id_produto);
    }));

    return () => {
      reset();
    }
  }, []);

  useEffect(() => {
    if (selectedEmpresaId) {
      getDataEmpresa(selectedEmpresaId);
    }
  }, [selectedEmpresaId]);

  useEffect(() => {
    if (openForm) return;

    const id_empresa = selectedEmpresaId;
    const id_pessoa = getValues('id_pessoa');
    const id_produto = getValues('id_produto');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_empresa || !data_inicio || !data_fim) {
      return;
    }

    carregaRetiradas(id_empresa, data_inicio, data_fim, id_pessoa, id_produto);
  }, [page, rowsPerPage, openForm]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/retiradas'>Retiradas</LinkNavegacao>
          <LinkNavegacao to='/epis/retiradas/devolucoes'>Devoluções</LinkNavegacao>
          <Typography variant='span'>Registrar Devolução</Typography>
        </Breadcrumbs>

        <PageTitle icon={<Restore fontSize='large' />} title='Registrar Devolução' />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('id_empresa', value, { shouldDirty: true });
                setValue('id_pessoa', '', { shouldDirty: true });
              }}
              textHelper={false}
            />
          </Grid>

          {colaboradores &&
            <Grid item xs={12} md={6} lg={4}>
              <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                <Controller
                  name="id_pessoa"
                  control={control}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Autocomplete
                      {...field}
                      value={value || null}
                      options={colaboradores.length > 0 ? [{ id: 'all', matricula: '', nome: 'Todos os colaboradores', status: 1 }, ...colaboradores] : []}
                      getOptionLabel={(option) => {
                        const pessoa = colaboradores.find(p => p.id === option.id);
                        return option.id === 'all' ? 'Todos os colaboradores' : pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                      }}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                          {option.id !== 'all' && `${option.matricula} -`} {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                        </MenuItem>
                      )}
                      onChange={(e, value) => setValue('id_pessoa', value ? value : null, { shouldDirty: true })}
                      loading={isLoadingCompanyData}
                      loadingText="Carregando..."
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Colaborador"
                          error={!!errors.id_pessoa}
                          helperText={getFormErrorMessage(errors, 'id_pessoa')}
                          size="small"
                          disabled={!selectedEmpresaId}
                          InputProps={{
                            ...params.InputProps,
                            style: { backgroundColor: '#fff' },
                            endAdornment: (
                              <>
                                {isLoadingCompanyData ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      noOptionsText="Nenhum disponível"
                    />
                  )}
                />
              </TooltipSelectDisabled>
            </Grid>
          }

          {produtos &&
            <Grid item xs={12} md={6} lg={4}>
              <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                <Controller
                  name="id_produto"
                  control={control}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Autocomplete
                      {...field}
                      value={value || null}
                      options={produtos.length > 0 ? [{ id: 'all', ca: '', nome: 'Todos os produtos', status: 1 }, ...produtos] : []}
                      getOptionLabel={(option) => {
                        const product = produtos.find(p => p.id === option.id);
                        return option.id === 'all' ? 'Todos os produtos' : product ? `${product.ca} - ${product.nome}` : '';
                      }}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                          {option.id !== 'all' && `${option.ca} -`} {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                        </MenuItem>
                      )}
                      onChange={(e, value) => setValue('id_produto', value ? value : null, { shouldDirty: true })}
                      loading={isLoadingCompanyData}
                      loadingText="Carregando..."
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Produto"
                          error={!!errors.id_produto}
                          helperText={getFormErrorMessage(errors, 'id_produto')}
                          size="small"
                          disabled={!selectedEmpresaId}
                          InputProps={{
                            ...params.InputProps,
                            style: { backgroundColor: '#fff' },
                            endAdornment: (
                              <>
                                {isLoadingCompanyData ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      noOptionsText="Nenhum disponível"
                    />
                  )}
                />
              </TooltipSelectDisabled>
            </Grid>
          }
        </Grid>

        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={6} md={4} lg={4}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Início"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              maxDate={dayjs()}
              dayOfWeekFormatter={renderDay}
              value={getValues('data_inicio')}
              onChange={(data) => {
                setValue('data_inicio', data, { shouldDirty: true })
                if (data && data.isBefore(dayjs())) {
                  clearErrors('data_inicio');
                }
              }}
              onError={(error) => {
                if (error) {
                  const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                  setError("data_inicio", { type: "invalid", message: errorMessage })
                }
              }}
              slotProps={{
                textField: {
                  error: !!errors.data_inicio,
                  helperText: errors.data_inicio ? errors.data_inicio.message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                }
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Fim"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              dayOfWeekFormatter={renderDay}
              value={getValues('data_fim')}
              onChange={(data) => setValue('data_fim', data, { shouldDirty: true })}
              slotProps={{
                textField: {
                  error: !!errors.data_fim,
                  helperText: errors.data_fim ? errors.data_fim.message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                },
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
              <Button
                fullWidth
                type='submit'
                color='primary'
                variant='contained'
                disabled={
                  isLoading ||
                  Object.keys(errors).length > 0 ||
                  !verifyFieldsAreFilled([getValues('id_empresa'), getValues('data_inicio'), getValues('data_fim')])
                }
                startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <Search />}
              >
                Filtrar
              </Button>

              <Button
                fullWidth
                variant="outlined"
                startIcon={<Restore />}
                onClick={clearFilters}
              >
                Limpar Filtros
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="Retiradas">
            <TableHead>
              <TableRow sx={{ display: 'flex' }}>
                <TableCell sx={{ flex: 2 }}>Código</TableCell>
                <TableCell sx={{ flex: 2 }}>Colaborador</TableCell>
                <TableCell sx={{ flex: 3 }}>Produto</TableCell>
                <TableCell sx={{ flex: 2 }}>Equipamento</TableCell>
                <TableCell sx={{ flex: 2 }}>Data</TableCell>
                <TableCell sx={{ flex: 1 }} />
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? <LoadingTableRow />
                : retiradas && retiradas?.length > 0
                  ? retiradas.map((item) =>
                    <RetiradaRow key={item.id} data={item} handleOpenEditForm={handleEdit} />
                  )
                  : <EmptyTableRow colSpan={7} />
              }
            </TableBody>
          </Table>

          <CustomTablePagination
            numTotalItems={numTotalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </TableContainer>
      </Grid>

      {selectedItem &&
        <DevolucaoForm
          open={openForm}
          setOpen={setOpenForm}
          selectedItem={selectedItem}
          selectedEmpresaId={selectedEmpresaId}
        />
      }
    </Grid>
  );
}