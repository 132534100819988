import React, { useEffect, useState } from 'react';
import {
    Divider,
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    FormGroup,
    Typography,
    Paper,
    Avatar,
    Stack,
    Checkbox,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { WearEarPlugAndGogglesIcon } from '../common/Icons';
import { Notifications, NotificationsOff } from '@mui/icons-material';

function GHEProduct({ control, register, errors, setValue, getValues, produto, globalPeriod, globalAlert, globalQuantity }) {
    const indexCategoria = produto.id_categoria;

    const [isChecked, setIsChecked] = useState(getValues(`permissoes[${produto.id}].sobrescreverCategoria`) ?? false)
    const isActive = getValues(`permissoes[${produto.id}].status`) ?? true;

    const [periodo, setPeriodo] = useState(getValues(`permissoes[${produto.id}].periodo`) ?? 1);

    // caso periodo seja diferente de GlobalPeriod, significa que foi alterado pelo usuário
    const [campoAtivado, setCampoAtivado] = useState(globalPeriod !== periodo);

    useEffect(() => {
        register(`permissoes[${produto.id}].status`, { value: isActive });
        register(`permissoes[${produto.id}].sobrescreverCategoria`, { value: isChecked });
    }, [produto.id, isActive, isChecked]);

    useEffect(() => {
        const initialValue = getValues(`permissoes[${produto.id}].sobrescreverCategoria`);
        setIsChecked(initialValue ?? true); // Define o estado inicial baseado no valor do formulário ou true como fallback
    }, [getValues, produto.id]);

    useEffect(() => {
        if (!isChecked) {
            setValue(`permissoes[${produto.id}].periodo`, globalPeriod)
            setValue(`permissoes[${produto.id}].ponto_alerta`, globalAlert)
            setValue(`permissoes[${produto.id}].quantidade_maxima`, globalQuantity)
        }
    }, [globalPeriod, globalAlert, globalQuantity, isChecked]);

    return (<>
        <input
            type="hidden"
            {...register(`permissoes[${produto.id}].id_produto`, { value: globalQuantity })}
        />

        <input
            type="hidden"
            {...register(`permissoes[${produto.id}].quantidade_maxima`, { value: produto.id })}
        />

        <Paper sx={{ mt: 2, backgroundColor: isChecked ? 'rgba(255, 252, 230, 1)' : 'inherit' }}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
                sx={{ p: 1 }}
            >
                <Grid item xs={2}>
                    <FormGroup>
                        <Controller
                            name={`permissoes[${produto.id}].status`}
                            control={control}
                            defaultValue={isActive} // Isso já "registra" o campo com o valor inicial
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size='small'
                                            {...field} // Automaticamente liga o switch ao formulário com os handlers corretos
                                            checked={field.value}
                                        />
                                    }
                                    label={field.value ? 'Ativo' : 'Inativo'}
                                />
                            )}
                        />
                    </FormGroup>
                    <Avatar>
                        <WearEarPlugAndGogglesIcon fontSize='large' />
                    </Avatar>
                </Grid>
                <Grid item xs={10}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ mb: 1 }}
                    >
                        <Typography variant='body2' sx={{ fontWeight: 'bold', pt: 1 }}>
                            {produto?.nome}
                        </Typography>
                        <Typography variant='body2' sx={{ pt: 1 }}>
                            <strong>CA:</strong> {produto?.ca}
                        </Typography>
                        <Typography variant='body2' sx={{ pt: 1 }}>
                            <strong>Vida útil:</strong> {produto.vida_util ? `${produto.vida_util} dia(s)` : '--'}
                        </Typography>
                    </Stack>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <Grid item xs={12} md={4}>
                            <Controller
                                name={`permissoes[${produto.id}].sobrescreverCategoria`}
                                control={control}
                                defaultValue={isChecked} // Define um valor padrão para o Controller
                                render={({ field: { onChange, value, ...field } }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={value ?? isChecked} // Usa o valor do formulário ou o valor inicial se undefined
                                                size="small"
                                                onChange={(event) => {
                                                    onChange(event.target.checked); // Atualiza o valor no React Hook Form
                                                    setIsChecked(event.target.checked); // Atualiza o estado local
                                                }}
                                            />
                                        }
                                        label={<Typography variant='body2'>Sobrescrever dados da categoria</Typography>}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                disabled={!isChecked}
                                {...register(`permissoes[${produto.id}].periodo`, { required: isChecked })} // A propriedade required pode ser dinâmica
                                InputLabelProps={{ shrink: true }}
                                margin="dense"
                                size='small'
                                label="Período"
                                defaultValue={1}
                                type='number'
                                fullWidth
                                onChange={(e) => {
                                    setValue(`permissoes[${produto.id}].periodo`, e.target.value)
                                    setPeriodo(e.target.value)
                                }}
                                variant="outlined"
                                inputProps={{ min: 1 }}
                            />

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={!campoAtivado || !isChecked}
                                fullWidth
                                margin="dense"
                                size="small"
                                label="Ponto de Alerta"
                                defaultValue={0}
                                type="number"
                                required
                                name={`permissoes[${produto.id}].ponto_alerta`}
                                autoComplete="off"
                                variant="outlined"
                                {...register(`permissoes[${produto.id}].ponto_alerta`)}
                                inputProps={{ min: 1, max: (periodo - 1) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle campo visibility"
                                                onClick={() => isChecked && setCampoAtivado((atual) => {
                                                    atual ? setValue(`permissoes[${produto.id}].ponto_alerta`, 0) : setValue(`permissoes[${produto.id}].ponto_alerta`, 1);

                                                    return !atual
                                                })
                                                }
                                                edge="start"
                                            >
                                                {campoAtivado ? <Notifications /> : <NotificationsOff />} {/* Ícone de cadeado aberto ou fechado */}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </>);
}

export default GHEProduct;