import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form"
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import Logotipo from "../../../assets/images/stw_logo_preto.svg"
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import { getFormErrorMessage, validatePasswordRegex } from '../../../configs/functions';
import { alterarSenhaTemporaria } from '../management/people/configs/functions';
import { useAppContext } from '../../../contexts/AppContext';

export function GeneratePassword() {
    const { dadosUsuario, signOut, updateDadosUsuarioLocalStorage } = useAppContext();
    const { exibirAlerta } = useCommonItems();

    const [newPasswordVisible, setNewPasswordVisible] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (data.new_password.length < 8) {
            setError('new_password', { type: 'manual', message: 'A senha deve ter no mínimo 8 caracteres.' });
            return;
        }

        if (data.new_password !== data.new_password_confirmation) {
            setError('new_password_confirmation', { type: 'manual', message: 'As senhas não conferem.' });
            exibirAlerta('As senhas não conferem.', '', 'error');
            return;
        }

        if (validatePasswordRegex(data.new_password) === false) {
            setError('new_password', { type: 'manual', message: 'A senha deve conter ao menos uma letra e um número.' });
            return;
        }

        const dataToSend = {
            nova_senha: data.new_password,
            nova_senha_confirmation: data.new_password_confirmation,
        };

        try {
            const response = await alterarSenhaTemporaria(dataToSend);
            if (response) {
                // const message = response.data.message ?? 'Senha gerada com sucesso.';
                const message = 'Senha gerada com sucesso!';
                exibirAlerta(message, '', 'success');


                // atualizar dados do usuário
                dadosUsuario.primeiro_acesso = false;
                dadosUsuario.senha = data.new_password;

                updateDadosUsuarioLocalStorage(dadosUsuario);

                // redirect to home appRoutes
                navigate('/');
            }
        } catch (error) {
            const messageError = error.response.data.error ?? 'Ocorreu um erro.';
            exibirAlerta(messageError, '', 'error')
        }
    }

    return (<>
        <Helmet title="Gerar Senha" defer={false} />

        <Container maxWidth={'sm'}>
            <Paper sx={{ p: 3, mt: 8, borderTopColor: "primary.main", borderTopStyle: 'solid', borderTopWidth: 8 }} elevation={4}>
                <Box
                    component={'img'}
                    src={Logotipo}
                    sx={{
                        height: 'auto',
                        width: '36%',
                        margin: "0 auto",
                        display: "block",
                        mb: 2
                    }}
                />

                <Typography variant='h2' component={'h1'} textAlign={'center'}>
                    Seja Bem Vindo, {dadosUsuario.pessoa.nome}!
                </Typography>

                <Divider variant='middle' sx={{ my: 2 }} />

                <Typography p textAlign={'center'}>
                    Crie uma nova senha para acessar o sistema.
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth sx={{
                        px: {
                            xs: 1,
                            lg: 7
                        },
                        my: 3
                    }}>
                        <TextField
                            margin='dense'
                            placeholder='Deve ter no mínimo 8 caracteres.'
                            id="new_password"
                            label='Nova Senha'
                            name='new_password'
                            type={newPasswordVisible ? 'text' : 'password'}
                            error={!!errors.new_password}
                            helperText={getFormErrorMessage(errors, 'new_password')}
                            fullWidth
                            minLength={8}
                            {...register("new_password", { required: true })}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>,
                                endAdornment:
                                    <IconButton
                                        onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                                        {newPasswordVisible
                                            ? <VisibilityOff />
                                            : <Visibility />
                                        }
                                    </IconButton>
                            }}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            margin='dense'
                            placeholder='Deve ter no mínimo 8 caracteres.'
                            label='Confirme sua nova Senha'
                            id="new_password_confirmation"
                            name='new_password_confirmation'
                            type={newPasswordVisible ? 'text' : 'password'}
                            error={!!errors.new_password_confirmation}
                            helperText={getFormErrorMessage(errors, 'new_password_confirmation')}
                            fullWidth
                            {...register("new_password_confirmation", { required: true })}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>,
                                endAdornment:
                                    <IconButton onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                                        {newPasswordVisible
                                            ? <VisibilityOff />
                                            : <Visibility />
                                        }
                                    </IconButton>
                            }}
                            sx={{ mb: 2 }}
                        />
                    </FormControl>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Button variant='contained' color='background' onClick={() => signOut()}>
                            Sair
                        </Button>

                        <Button variant='contained' type='submit'>
                            Atualizar
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </Container>
    </>);
}