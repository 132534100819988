import { React } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { LinkMenu } from '../../../../configs/functions';
import MenuTitle from '../../../common/MenuTitle';
import { useAppContext } from '../../../../contexts/AppContext';
import { CheckListIcon, ElectronicsIcon, HomePageIcon, NotificationIcon, PresentationIcon, ProtectiveGearAIcon, RequestServiceIcon, SafetyHatIcon, StatisticsIcon } from '../../../common/Icons';


function MenuEpis({ openMenuEpi, handleClick }) {

    const { setDrawerOpen } = useAppContext();

    return (<>
        <MenuTitle title="Gestão de EPI's" icon={<SafetyHatIcon />} stateVar={openMenuEpi} fChangeState={() => { handleClick() }} />
        <Collapse in={openMenuEpi} timeout="auto" unmountOnExit>
            <List>
                <LinkMenu url='/' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <HomePageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Página Inicial" />
                    </ListItemButton>
                </LinkMenu>

                <LinkMenu url='/epis' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PresentationIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                </LinkMenu>

                <LinkMenu url='/epis/produtos' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ProtectiveGearAIcon />
                        </ListItemIcon>
                        <ListItemText primary="Produtos" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/epis/retiradas' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <RequestServiceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Retiradas" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/epis/diretrizes' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Diretrizes" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/epis/equipamentos' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ElectronicsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Equipamentos" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/epis/alertas' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <NotificationIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alertas" />
                    </ListItemButton>
                </LinkMenu>
                <LinkMenu url='/epis/relatorios' setDrawerOpen={setDrawerOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <StatisticsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Relatórios" />
                    </ListItemButton>
                </LinkMenu>
            </List>
        </Collapse></>);
}

export default MenuEpis;