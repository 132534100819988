import { Breadcrumbs, Grid, Typography } from '@mui/material';
import PageTitle from '../../../common/PageTitle';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';
import { ProductDocumentsIcon } from '../../../common/Icons';

function Organization() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <Typography variant='span'>Gerenciamento</Typography>
                    <Typography variant='span'>Organização</Typography>
                </Breadcrumbs>
                <PageTitle icon={<ProductDocumentsIcon fontSize='large' />} title='Organização' />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h6' mt={1}>
                    Administre a organização das empresa.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Departamentos" description="Gerencie os departamentos da sua empresa." url='departamentos' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Áreas" description="Gerencie os áreas da sua empresa." url='areas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Setores" description="Gerencie os setores da sua empresa." url='setores' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Centros de Custo" description="Gerencie os centros de custo da sua empresa." url='centros_de_custo' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Tipos de Documento" description="Gerencie os tipos de documentos que podem ser atribuídos a um colaborador." url='tipos_de_documento' />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h6' mt={1}>
                    Administre os pontos de entrega e estoque das empresas.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Pontos de Entrega" description="Gerencie os pontos de entrega das empresas." url='pontos_de_entrega' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Estoque" description="Gerencie os estoque das empresas." url='estoque' />
            </Grid>
        </Grid>
    </>);
}

export default Organization;